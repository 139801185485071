import React, { useState } from "react";
import styled from "styled-components";

const RadioButtonLabel = styled.label`
  display: inline-block;
  position: relative;
  padding-left: 20px; /* Increased padding to accommodate the larger button */
  margin-right: 0px;
  cursor: pointer;
  font-size: 18px;
  user-select: none;
`;

const RadioButtonText = styled.div`
  font-size: 14px;
  margin-top: 18px;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 25px;
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
`;

const HiddenRadioButton = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
`;

const CustomRadioButtonControl = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 15px; /* Increased height */
  width: 15px; /* Increased width */
  background-color: white;
  border: 0.625px solid black; /* Border increased proportionally */
  border-radius: 50%;

  ${HiddenRadioButton}:checked + & {
    border: 8.5px solid #2d70e2; /* Border increased proportionally */
  }

  ${HiddenRadioButton}:checked + &::after {
    content: "";
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    width: 8.5px; /* Inner circle increased proportionally */
    height: 8.5px; /* Inner circle increased proportionally */
    background: white;
    border-radius: 50%;
    transform: translate(-50%, -50%);
  }
`;

const CustomRadioButton = ({ name, value, checked, onChange }) => {
  return (
    <RadioButtonLabel>
      <HiddenRadioButton
        type="radio"
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
      />
      <CustomRadioButtonControl />
    </RadioButtonLabel>
  );
};

const RadioButtonGroup = ({ onChange, options, selected }) => {
  const [selectedValue, setSelectedValue] = useState(selected);

  const handleRadioChange = (e) => {
    setSelectedValue(e.target.value);
    onChange(e.target.value);
  };

  return (
    <ButtonGroup>
      {options?.map((option, index) => {
        return (
          <ButtonContainer key={index}>
            <CustomRadioButton
              name="example"
              value={option.value}
              checked={Number(selectedValue) === option.value}
              onChange={handleRadioChange}
            />
            <RadioButtonText>{option.name}</RadioButtonText>
          </ButtonContainer>
        );
      })}
    </ButtonGroup>
  );
};

export default RadioButtonGroup;
