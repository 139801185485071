import {
  GET_TASK_TEMPLATES,
  UPDATE_TASK_TEMPLATE,
  DELETE_TASK_TEMPLATE,
  ADD_TASK_TEMPLATE,
  CLEAR_TASK_TEMPLATE,
  ADD_USER_TASK,
  DELETE_USER_TASK,
  GET_TASK_TEMPLATE_SELECT,
  CREATE_TASK_SCHEDULE,
  GET_TASK_SCHEDULES,
  FETCH_TASK_TEMPLATE,
  FETCH_TASK_SCHEDULE,
  GENERATE_TASK_REPORT,
  ADD_EMPLOYEE_REPORT_ACCESS,
  REMOVE_EMPLOYEE_REPORT_ACCESS,
  CLEAR_FETCHED_SCHEDULED_TASK,
} from "constants/actions";

const initialState = {
  task_templates: [],
  task_templates_select_list: [],
  scheduled_tasks: [],
  created_scheduled_task: {},
  fetched_template: {},
  fetched_scheduled_task: {},
  task_report: {},
  task_report_list: [],
  pending: false,
  reportCreated: false,
  response: null,
  created: [],
};

export default function TaskTemplates(state = initialState, action) {
  switch (action.type) {
    case GET_TASK_TEMPLATES:
      return {
        ...state,
        task_templates: action.payload,
        pending: false,
      };

    case UPDATE_TASK_TEMPLATE:
      const updatedTaskTemplates = {
        ...state.task_templates,
        results: state.task_templates.results.map((row) =>
          row.id === action.payload.id
            ? {
                ...row,
                ...action.payload,
              }
            : row
        ),
      };
      return {
        ...state,
        task_templates: updatedTaskTemplates,
      };

    case DELETE_TASK_TEMPLATE:
      const deletedTaskTemplates = {
        ...state.task_templates,
        results: state.task_templates.results.filter(
          (row) => row.id !== action.payload
        ),
      };
      return {
        ...state,
        task_templates: deletedTaskTemplates,
      };
    case CLEAR_TASK_TEMPLATE:
      return {
        ...state,
        created: [],
        reportCreated: false,
        created_scheduled_task: {},
      };

    case ADD_TASK_TEMPLATE:
      // Add the payload to the front of the task_templates_select_list array
      const newTaskTemplatesSelect = [
        action.payload,
        ...state.task_templates_select_list,
      ];

      return {
        ...state,
        created: action.payload,
        task_templates_select_list: newTaskTemplatesSelect,
      };

    case ADD_USER_TASK:
      const updatedFetchTemplate = {
        ...state.fetched_scheduled_task,
        assigned_employees: [
          ...state.fetched_scheduled_task.assigned_employees,
          ...action.payload.employee_id_list,
        ],
      };

      // also update the scheduled_tasks list
      const updatedScheduledTasks = {
        ...state.scheduled_tasks,
        results: state.scheduled_tasks.results.map((row) =>
          row.id === state.fetched_scheduled_task.id
            ? {
                ...row,
                ...updatedFetchTemplate,
              }
            : row
        ),
      };

      return {
        ...state,
        fetched_scheduled_task: updatedFetchTemplate,
        scheduled_tasks: updatedScheduledTasks,
      };

    case DELETE_USER_TASK:
      const updatedFetchTemplateDelete = {
        ...state.fetched_template,
        assigned_employees: state.fetched_template.assigned_employees.filter(
          (employee) => !action.payload.employee_id_list.includes(employee)
        ),
      };

      return {
        ...state,
        fetched_template: updatedFetchTemplateDelete,
      };

    case GET_TASK_TEMPLATE_SELECT:
      return {
        ...state,
        task_templates_select_list: action.payload,
        pending: false,
      };

    case CREATE_TASK_SCHEDULE:
      // const newScheduledTasks = {
      //   ...state.scheduled_tasks,
      //   results: [...state.scheduled_tasks.results, action.payload],
      // };
      return {
        ...state,
        // scheduled_tasks: newScheduledTasks,
        created_scheduled_task: action.payload,
        pending: false,
      };

    case GET_TASK_SCHEDULES:
      return {
        ...state,
        scheduled_tasks: action.payload,
        pending: false,
      };

    case FETCH_TASK_TEMPLATE:
      return {
        ...state,
        fetched_template: action.payload,
        pending: false,
      };

    case FETCH_TASK_SCHEDULE:
      return {
        ...state,
        fetched_scheduled_task: action.payload,
        pending: false,
      };

    case GENERATE_TASK_REPORT:
      return {
        ...state,
        task_report: action.payload,
        reportCreated: true,
        pending: false,
      };

    case ADD_EMPLOYEE_REPORT_ACCESS:
      const updatedFetchedTask = {
        ...state.fetched_scheduled_task,
        employee_access: [
          ...state.fetched_scheduled_task.employee_access,
          ...action.payload?.employee_id_list,
        ],
      };

      return {
        ...state,
        fetched_scheduled_task: updatedFetchedTask,
      };

    case REMOVE_EMPLOYEE_REPORT_ACCESS:
      const updatedFetchedTaskRemove = {
        ...state.fetched_scheduled_task,
        employee_access: state.fetched_scheduled_task.employee_access.filter(
          (employee) => employee !== action.payload?.employee_id_list[0]
        ),
      };

      return {
        ...state,
        fetched_scheduled_task: updatedFetchedTaskRemove,
      };

    case CLEAR_FETCHED_SCHEDULED_TASK:
      return {
        ...state,
        fetched_scheduled_task: {},
      };

    default:
      return state;
  }
}
