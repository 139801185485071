import styled, { keyframes } from 'styled-components';
import React, { useEffect, useCallback, useState, useRef } from "react";
import { Input } from 'semantic-ui-react'
import {
  process_feedback_comments,
  process_feedback_data,
  processCommentQuestions,
} from "../calculations";
import { useSelector, shallowEqual } from "react-redux";
import { filter_data } from "reports/Tag/calculations";
import ChatModal from "./components/ChatModal";
import AICopilot from "./components/AICopilot";
import { Pagination } from "semantic-ui-react";
import Spark from "assets/images/UI/mdi_sparkles-outline.svg";
import Empty from "./components/EmptyFeedback";
import FeedbackTag from "./FeedbackTag";
import { useLocation } from "react-router";

const Feedback = ({ data, core_data, outcomeQ, navigation, originalData }) => {
  const [feedback, setFeedback] = useState();
  const location = useLocation();
  const [unfilteredFeedback, setUnfilteredFeedback] = useState();
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [AIChatOpen, setAIChatOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [displayedFeedback, setDisplayedFeedback] = useState();
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedUserFeedback, setSelectedUserFeedback] = useState(null);
  const pageSize = 10;
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [orientation, setOrientation] = useState(null);
  const [search, setSearch] = useState(null);
  const [selectedQuestion, setQuestion] = useState(null);
  const isOTP = location.pathname.includes("otp");
  const [feedbackType, setFeedbackType] = useState(isOTP ? 1 : 0);

  const { selectedFilters } = useSelector(
    (state) => ({
      selectedFilters: state.audit?.new_filters,
    }),
    shallowEqual
  );

  const getFactorName = (factor_id, dimension) => {
    return core_data?.questions?.dimensions?.[dimension]?.factors?.find(
      (f) => f.id == factor_id + 1
    )?.title;
  };

  const copilot = (
    <AIButton onClick={() => setAIChatOpen(true)}>
      Use the culture copilot <IMG2 src={Spark} />
    </AIButton>
  );

  function changedPage(e, { activePage }) {
    // console.log(activePage)
    setActivePage(activePage);
  }

  useEffect(() => {
    if (selectedConversation && unfilteredFeedback) {
      const userFeedback = unfilteredFeedback.filter(
        (item) => item.employee === selectedConversation.employee
      );

      setSelectedUserFeedback(userFeedback);
    }
  }, [selectedConversation]);

  useEffect(() => {
    if (feedback && feedback) {
      // When the page changes we need to update the displayed insights
      const start = (activePage - 1) * pageSize;
      const end = start + pageSize;
      let filteredFeedback = feedback;
      if (search) {
        filteredFeedback = feedback.filter((item) =>
          item.feedback?.toLowerCase().includes(search.toLowerCase())
        );
      }
      setTotalPages(Math.ceil(filteredFeedback.length / pageSize));
      setDisplayedFeedback(filteredFeedback.slice(start, end));
    }
  }, [activePage, feedback, search]);

  const getOutcomeName = (outcome_id, outcomeQ) => {
    return outcomeQ?.questions?.find((o) => Number(o.id) === Number(outcome_id))
      ?.name;
  };

  useEffect(() => {
    if (data?.[0]?.[0]) {
      if (
        !isOTP &&
        core_data?.questions?.comments?.length > 0 &&
        feedbackType === 1
      ) {
        setUnfilteredFeedback(
          processCommentQuestions(
            core_data?.questions?.comments,
            data?.[0],
            undefined
          )
        );
        return;
      }

      if (isOTP) {
        setUnfilteredFeedback(process_feedback_comments(data[0], undefined));
        return;
      } else {
        setUnfilteredFeedback(
          process_feedback_data(data?.[0], undefined, true)
        );
        return;
      }
    } else {
      if (
        (Array.isArray(data[0]) && data[0].length > 0) ||
        (!Array.isArray(data[0]) && data.length > 0)
      ) {
        if (
          !isOTP &&
          core_data?.questions?.comments?.length > 0 &&
          feedbackType === 1
        ) {
          setUnfilteredFeedback(
            processCommentQuestions(
              core_data?.questions?.comments,
              data,
              undefined
            )
          );
          return;
        }

        if (isOTP) {
          setUnfilteredFeedback(process_feedback_comments(data, undefined));
          return;
        } else {
          setUnfilteredFeedback(process_feedback_data(data, undefined, true));
        }
      }
    }
  }, [data, feedbackType]);

  useEffect(() => {
    let filteredData;
    let feedbackData;

    if (data?.[0]?.[0]) {
      filteredData = filter_data(data[0], selectedFilters);
    } else {
      filteredData = filter_data(data, selectedFilters);
    }

    if (
      !isOTP &&
      core_data?.questions?.comments?.length > 0 &&
      feedbackType === 1
    ) {
      feedbackData = processCommentQuestions(
        core_data?.questions?.comments,
        filteredData,
        navigation
      );

      if (selectedQuestion) {
        feedbackData = feedbackData.filter(
          (item) => item.id === selectedQuestion
        );
      }

      setFeedback(feedbackData?.filter((item) => item.feedback));
    } else if (isOTP) {
      feedbackData = process_feedback_comments(filteredData, navigation);

      if (selectedQuestion) {
        feedbackData = feedbackData.filter(
          (item) => item.id === selectedQuestion
        );
      }

      setFeedback(feedbackData?.filter((item) => item.feedback));
    } else {
      if (selectedFilter) {
        feedbackData = process_feedback_data(filteredData, navigation);

        feedbackData = feedbackData.filter(
          (item) => item?.nlp?.sentiment === selectedFilter
        );

        if (orientation) {
          feedbackData = feedbackData.filter(
            (item) => item?.nlp?.action_orientation === orientation
          );
        }
      } else if (orientation) {
        feedbackData = process_feedback_data(filteredData, navigation);

        feedbackData = feedbackData.filter(
          (item) => item?.nlp?.action_orientation === orientation
        );
      } else {
        feedbackData = process_feedback_data(filteredData, navigation);
      }

      setFeedback(feedbackData?.filter((item) => item.feedback));
    }

    // Reset page to 1 when filtering and recalculate total pages
    setActivePage(1);
    setTotalPages(Math.ceil(feedbackData.length / pageSize));
  }, [
    navigation,
    data,
    selectedFilters,
    selectedFilter,
    orientation,
    selectedQuestion,
    feedbackType,
  ]);

  const getColor = (type) => {
    const colors = {
      "Very Negative": "#de2121",
      "Slightly Negative": "#de2121",
      Neutral: "#f7b531",
      "Slightly Positive": "#33b054",
      "Very Positive": "#33b054",
    };

    return colors[type];
  };

  if (core_data && core_data.noData) {
    return (
      <Container>
        <Empty />
      </Container>
    );
  }

  return (
    <>
      {AIChatOpen && (
        <AICopilot
          open={AIChatOpen}
          setOpen={setAIChatOpen}
          navigation={navigation}
          feedbackData={feedback}
        />
      )}
      <Container chatOpen={AIChatOpen}>
        <FeedbackTag
          selectedQuestion={selectedQuestion}
          setQuestion={setQuestion}
          outcomeQ={outcomeQ}
          data={feedback}
          setFilter={setSelectedFilter}
          setOrientation={setOrientation}
          selectedSentiment={selectedFilter}
          copilot={copilot}
          isOTP={isOTP}
          showComments={core_data?.questions?.comments?.length > 0}
          selectedFilter={selectedFilter}
          setFeedbackType={setFeedbackType}
          feedbackType={isOTP ? 1 : feedbackType}
        />
        <ChatModal open={open} setOpen={setOpen} data={selectedUserFeedback} />

        <Input
          icon={{ name: "search", circular: true, link: true }}
          style={{ marginBottom: 10, width: "100%" }}
          placeholder="Keyword search..."
          onChange={(e) => {
            setActivePage(1);

            setSearch(e.target.value);
          }}
          value={search}
        />
        {(displayedFeedback &&
          ((displayedFeedback?.length > 0 &&
            displayedFeedback.map((item, i) => {
              if (item.feedback == "") return null;
              return (
                <Row key={i}>
                  {/* <Tags>
                    <Tag>{item.type}</Tag>
                    <Tag>
                      {item?.outcome
                        ? getOutcomeName(item?.outcome, outcomeQ)
                        : getFactorName(item.factor, item.dimension)}
                    </Tag>
                  </Tags> */}
                  {/* <Label>Question:</Label>
              <Q>
                {item.follow_up_question
                  ? item.follow_up_question
                  : item.question}
              </Q> */}
                  <BlueText>
                    {item?.outcome
                      ? getOutcomeName(item?.outcome, outcomeQ)
                      : getFactorName(item.factor, item.dimension)}{" "}
                    Feedback
                  </BlueText>

                  <BottomTags>
                    {item?.nlp?.sentiment && (
                      <>
                        This feedback is
                        <SentimentTag color={getColor(item.nlp.sentiment)}>
                          {item?.nlp?.sentiment}
                        </SentimentTag>
                      </>
                    )}

                    {item?.nlp?.action_orientation && (
                      <Tag2>
                        and{" "}
                        {item.nlp.action_orientation == "action_oriented"
                          ? "Actionable"
                          : "Not Actionable"}
                      </Tag2>
                    )}
                  </BottomTags>

                  <Text>{item.feedback}</Text>

                  {item.follow_up_question && item.follow_up_response && (
                    <>
                      <BlueText>Ai follow up</BlueText>
                      <Label>{item.follow_up_question}</Label>
                      <Text>{item.follow_up_response}</Text>
                    </>
                  )}

                  <Action
                    onClick={() => {
                      setOpen(true);
                      setSelectedConversation(item);
                    }}
                  >
                    See all feedback for this user
                  </Action>
                </Row>
              );
            })) || <Empty />)) || <>Loading...</>}
      </Container>
      {displayedFeedback?.length > 0 && (
        <Pagination
          activePage={activePage}
          ellipsisItem={null}
          firstItem={null}
          lastItem={null}
          totalPages={totalPages}
          onPageChange={changedPage}
        />
      )}
    </>
  );
};

export default Feedback;

const Container = styled.div`
  width: 100%;
  position: relative;
  // Remove scroll if chat is open
  overflow-y: ${(props) => (props.chatOpen ? "hidden" : "auto")};
  max-height: 100%;
  padding-bottom: 100px;
`;

const Row = styled.div`
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  border: 1px solid #f0f0f0;
  position: relative;
  padding: 20px;
  min-height: 100px;
`;

const ActionRow = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
`;

const AIButton = styled.div`
  background: #2d70e2;
  font-family: "Raleway", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 600;
  display: inline-block;
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    background: #333;
  }
  min-width: 200px;
  max-height: 45px;
  margin-left: 20px;
`;

const BottomTags = styled.div`
  display: flex;
  margin-bottom:15px;
   font-weight:bold;
    font-size:12px;
    color:#666D79;
`;

const IMG2 = styled.img`
  width: 20px;
  margin-left: 10px;
  margin-bottom: -5px;
`;

const TR = styled.div`
  width: ${(props) => props.width}%;
  display: flex;
  flex-direction: column;
  padding: 20px;
`;
const Q = styled.div`
  font-weight: bold;
  margin-bottom: 20px;
  max-width: calc(100% - 200px);
`;

const Text = styled.div`
  font-size: 14px;
  line-height: 1.6;
  color: #2A3039;
  margin-bottom: 10px;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
`;

const Description = styled.div`
    margin-bottom:30px;
    line-height:1.4;
`

const T2 = styled.div`
    font-size:12px;
    font-weight:bold;
    margin-bottom:10px;
`

const Score = styled.div`
    position:absolute;
    right:0;
    
`

const SentimentTag = styled.div`
    color:${props=>props.color};
    display:inline-block;
    margin-right:5px;
    margin-left:5px;
`

const Tag = styled.div`
    color:#524C71;
    font-weight:600;
    font-size:10px;
    background-color:#F8FAFF;
    padding:4px 8px;
    border-radius:5px;
    display:inline-block;
    margin-right:5px;
`

const Tag2 = styled.div`
    display:inline-block;
    margin-right:5px;
`

const Image = styled.img`
    width:30px;
    margin-right:40px;
`

const Tags = styled.div`
    position:absolute;
    top:20px;
    right:20px;
`

const Action = styled.div`
    color:#2D70E2;
    font-size:12px;
    cursor:pointer;
    margin-top:10px;
    display:flex;
    position:absolute;
    bottom:10px;
    right:20px;
`

const TableTitle = styled.div`
    font-size:16px;
    font-weight:bold;
    margin-bottom:10px;
    margin-top:20px;
`

const Label = styled.div`
    font-size:12px;
    font-weight:bold;
    text-decoration:italic;
    margin-bottom:5px;
`

const BlueText = styled.div`
    font-size:12px;
    font-weight:bold;
    color:#2D70E2;
    margin-bottom:5px;
`