import React, { useState, useEffect,useRef  } from "react";
import styled, { keyframes, css } from "styled-components";

import Intro from "./Intro";
import make_call,{generate_survey,get_suggestions} from './Request'
import Step1 from "./Step1";
import Start from "./Start";
import Questions from "./Questions";

const QuestionAI = () =>{
    const [step, setStep] = useState(0);
    const [loading, setLoading] = useState(false);
    const [answer, setAnswer] = useState("");
    const [history, setHistory] = useState([]);
    const [message, setMessage] = useState("");
    const [suggestions, setSuggestions] = useState([]);

    const [objective, setObjective] = useState("");

    const make_gpt_call = () => {
        setLoading(true);
        async function someFunction() {
          let _history = [...history];
    
          if (answer) {
            _history = [..._history, { role: "user", content: answer }];
            setAnswer("");
          }
          let resp
          if(step == 3){
            resp = await generate_survey(_history,objective);
          }
          else{
            resp = await make_call(_history,objective);
          }
          
    
          setMessage(resp);
    
          setHistory([..._history, { role: "assistant", content: resp }]);
    
          setLoading(false);
        }
    
        someFunction();
      };


     useEffect(()=>{
        async function someFunction() {
            const resp = await get_suggestions();
            setSuggestions(JSON.parse(resp));
            }
            someFunction()

        },[])

      useEffect(()=>{
        setMessage("")
            make_gpt_call()
        },[step])

     

    return (
        <Container>
            <ContentContainer>
                {step==0? <Start
                    text={objective}
                    setText={setObjective}
                    suggestions={suggestions}
                    />
                :
                step===3? <Questions questions={message} objective={objective}/> :
                <Step1
                loading={loading}
                answer={answer}
                setAnswer={setAnswer}
                question={message}/>}

                   



                <ButtonArea>
                <Button onClick={()=>setStep(step+1)}>Next</Button>
                </ButtonArea>
            </ContentContainer>
           
        </Container>
    )
}

export default QuestionAI;


const Container = styled.div`
    margin-top:50px;
    display:flex;
    justify-content:center;
    align-items:center;
    height:calc(100% - 50px);
    flex-direction:column;
`



const Button = styled.div`
         display:flex;
    justify-content:center;
    align-items:center;
    padding:10px 20px;
    color:#2254AA;
    font-size:16px;
    font-weight:600;
    cursor:pointer;
    border:1px solid #2254AA;



    `

const ButtonArea = styled.div`
    display:flex;
    width:100%;
    justify-content:flex-end;
    margin-top:40px;
`

const ContentContainer = styled.div`
    max-width:800px;
    width:100%;
`