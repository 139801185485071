import { all, fork } from "redux-saga/effects";

import Auth from "./Auth";
import GoogleAuth from "./GoogleAuth";
import AccountDefault from "./AccountDefault";
//
import LogErrors from "./LogErrors"
// reports
import EmotionDefinitions from "./EmotionDefinitions";
import Moods from "./Moods";
import Personality from "./Personality";
import Culture from "./Culture";
import Leadership from "./Leadership";
import EmployeeReport from "./EmployeeReport";
import Ambassador from "./Ambassadors";
import EmployeeCategory from "./EmployeeCategory";
import IndustryBenchmark from "./IndustryBenchmark";
import EngagementData from "./EngagementData";
import ChatGpt from "./ChatGpt";
// forms
import Departments from "./Departments";
import Organization from "./Organization";
import Employee from "./Employee";
import EmployeePermission from "./EmployeePermission";
import EmployeeRole from "./EmployeeRole";
import EmployeeLog from "./EmployeeLog";
import EmployeeRecord from "./EmployeeRecord";
import EmployeeRecordList from "./EmployeeRecordList";
import EmployeeRecordValue from "./EmployeeRecordValue";
import Payment from "./Payment";
import Price from "./Price";
import Teams from "./Teams";
import TeamType from "./TeamType";
import TeamAccess from "./TeamAccess";
// questions
import SurveyQuestion from "./SurveyQuestions";
// de brief
import DebriefSchedule from "./DebriefSchedule";
import DebriefResponse from "./DebriefResponse";
import DebriefToken from "./DebriefToken";
import DebriefEmail from "./DebriefEmail";
import DebriefPdfReport from "./DebriefPdfReport";
import SurveyStandard from "./SurveyStandard";

// settings
import FileUpload from "./FileUpload";
import SurveyStructure from "./SurveyStructure";
import SurveyToken from "./SurveyToken";
import SignUpToken from "./SignUpToken";
import SurveyResponses from "./SurveyResponses";
import GlobalSchedule from "./GlobalSchedule";
import SurveyScheduleFrequency from "./SurveyScheduleFrequency";
import TeamInSchedule from "./TeamInSchedule";
import LeadersInSchedule from "./LeadersInSchedule";
import LeadersSurveyParticipants from "./LeadersSurveyParticipants";
import SurveyCompliance from "./SurveyCompliance";
import PersonalityTeams from "./PersonalityTeams";
import PersonalityParticipants from "./PersonalityParticipants";
import ValidateEmail from "./ValidateEmail";
import OnBoarding from "./OnBoarding";
import EmailDeliveryStatus from "./EmailDeliveryStatus";
import Subscription from "./Subscription";
// list
import SurveySettingList from "./SurveySettingList";

// PDF Report
import PDFConfig from "./PDFConfig";
import PDFComments from "./PDFComments";
// Action Plans
import ActionPlan from "./ActionPlan";
import MemberFilters from "./MemberFilters";
// AI Chat
import AIChat from "./AIChat";
import AIWebsocket from "./AIWebsocket";
// Recommendations
import Recommendations from "./Recommendations";

import ReportTemplate from "./ReportTemplate";
import EventNotification from "./EventNotification";
import DataStories from "./DataStories";
import ScheduleReports from "./ScheduleReports";
import UserManager from "./UserManager";
import TaskTemplates from "./TaskTemplates";
import TaskResponses from "./TaskResponses";
import StoryLogging from "./StoryLogging";
import DownloadUrl from "./DownloadUrl";

export function* rootSaga() {
  yield all([
    fork(LogErrors),
    fork(Auth),
    fork(GoogleAuth),
    fork(AccountDefault),
    fork(EmotionDefinitions),
    fork(Moods),
    fork(Personality),
    fork(Culture),
    fork(Leadership),
    fork(Departments),
    fork(SurveyQuestion),
    fork(Organization),
    fork(Ambassador),
    fork(EmployeeReport),
    fork(IndustryBenchmark),
    fork(Employee),
    fork(EmployeePermission),
    fork(EmployeeRole),
    fork(EmployeeLog),
    fork(EmployeeRecord),
    fork(EmployeeRecordList),
    fork(EmployeeRecordValue),
    fork(EmployeeCategory),
    fork(EngagementData),
    fork(ChatGpt),
    fork(Payment),
    fork(DebriefEmail),
    fork(Price),
    fork(Teams),
    fork(DebriefSchedule),
    fork(DebriefResponse),
    fork(DebriefToken),
    fork(DebriefPdfReport),
    fork(SurveyStandard),
    fork(TeamAccess),
    fork(FileUpload),
    fork(SurveyStructure),
    fork(TeamType),
    fork(SurveyToken),
    fork(SignUpToken),
    fork(SurveyResponses),
    fork(SurveyCompliance),
    fork(GlobalSchedule),
    fork(SurveyScheduleFrequency),
    fork(TeamInSchedule),
    fork(LeadersInSchedule),
    fork(LeadersSurveyParticipants),
    fork(PersonalityTeams),
    fork(PersonalityParticipants),
    fork(ValidateEmail),
    fork(OnBoarding),
    fork(EmailDeliveryStatus),
    fork(Subscription),
    fork(SurveySettingList),
    fork(PDFConfig),
    fork(ActionPlan),
    fork(PDFComments),
    fork(MemberFilters),
    fork(AIChat),
    fork(AIWebsocket),
    fork(Recommendations),
    fork(ReportTemplate),
    fork(EventNotification),
    fork(DataStories),
    fork(ScheduleReports),
    fork(UserManager),
    fork(TaskTemplates),
    fork(TaskResponses),
    fork(StoryLogging),
    fork(DownloadUrl),
  ]);
}

export default rootSaga;
