import styled, { keyframes } from "styled-components";
import React, { useEffect, useState } from "react";

const LoadingSpinner = ({ loadingText }) => {
  return (
    <LoadingContainer>
      <Spinner />
      <LoadingText>{loadingText}</LoadingText>
    </LoadingContainer>
  );
};

const spin = keyframes`
0% {
  transform: rotate(0deg);
}
100% {
  transform: rotate(360deg);
}
`;

const Spinner = styled.div`
  border: 2px solid rgba(0, 0, 255, 0.2); /* Light blue background */
  border-top: 2px solid #2d70e2; /* Dark blue border */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: ${spin} 1s linear infinite;
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const LoadingText = styled.span`
  font-size: 14px;
  color: #2d70e2;
`;

export default LoadingSpinner;
