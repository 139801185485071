import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import {Input,Dropdown} from 'semantic-ui-react'

const Start = ({text,setText,suggestions}) => {
    const options = [
        { key: '1', text: 'ROI insights', value: 'ROI insights' },
        { key: '2', text: 'Lowest Culture Benchmark', value: 'Lowest Culture Benchmark' },
        { key: '3', text: 'All culture data', value: 'All culture data' },
        { key: '4', text: 'Dont include any additional information', value: 'Dont include any additional information' }
      ]

      const [selected, setSelected] = useState(null);

      useEffect(()=>{
         if(selected !==null){
                    setText(suggestions[selected].suggestion)
        }
            
      },[selected])

    return (
        <Container>
            <Title>
                Let AI help you get deeper insights.
            </Title>

            <Description>
                Our AI will help design a custom survey for you based on your culture data, and your specific needs. 
                The survey will follow best practices in survey design, and will ensure that you are asking the right questions to get the most accurate and actionable insights.
            </Description>

            <Text>
                Suggested Objective/Goal
            </Text>
            <Description>
                This will help us understand what you are looking to achieve with the survey. The more descriptive and specific you are here, the better the survey will be.
            </Description>

            {
                suggestions.map((suggestion,index)=>{
                    return <Suggestion data={suggestion} key={index} 
                    onClick={()=>setSelected(index)}
                    selected={selected == index}
                    />
                })
            }

            <Custom>
                Customize your objective/goal
            </Custom>


{/* 
            <TextArea 
            value={text}
            onChange={(e)=>setText(e.target.value)}
            placeholder="What is your objective/goal?" />



            <Text>
                What information would you like considered?
            </Text>

            <Description>
                Please select the information you would like us to consider when designing the survey.
            </Description>
            <Dropdown placeholder='What information would you like considered?' fluid selection options={options} /> */}


        </Container>
    )

}

export default Start;

const Suggestion = ({data,selected,onClick}) => {
    return (
        <SuggestionContainer onClick={onClick} selected={selected}>
            <SuggestionTitle>
                {data.title}
            </SuggestionTitle>
            <SuggestionText>
                {data.suggestion}
            </SuggestionText>
        </SuggestionContainer>
    )
}


const Container = styled.div`
font-family:'Raleway';
`

const SuggestionContainer = styled.div`
    margin-bottom:10px;
    border:1px solid #DFDFDF;
    padding:10px;
    cursor:pointer;

    ${props=>props.selected && `
        background-color:#F5F5F5;
    `}
`

const SuggestionTitle = styled.h3`

`

const SuggestionText = styled.p`

`

const Custom = styled.div`
        display:flex;
        justify-content:flex-end;
        width:100%;
        font-weight:600;
`


const Title = styled.h1`

`

const Description = styled.p`

`

const Text = styled.div`
    font-weight:600;
    margin-top:20px;
    margin-bottom:5px;
`

const TextArea = styled.textarea`
    width:100%;
    height:100px;
    border-radius:5px;
    border:1px solid #DFDFDF;
    padding:10px;
    margin-top:10px;
    resize:none;
    font-family:'Raleway';
    font-size:16px;
    font-weight:400;
    margin-bottom:10px;
`