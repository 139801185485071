import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { Icon, Progress, Dropdown, Menu, Checkbox } from "semantic-ui-react";

import { useSelector, shallowEqual, useDispatch } from "react-redux";
import ClickAwayListener from "react-click-away-listener";
import { build_default_State } from "./data_navigation/calculate_data";

import { SET_NAVIGATION, SET_SELECTED_FILTERS } from "constants/actions";
import X from 'assets/images/nav/X.svg'
import Languages from 'language/index'
import {useLanguageSetting} from 'language/redux_store'
import { show } from "settings/survey_settings/styled_list";

const MenuItem = ({
  title,
  selections,
  onSelect,
  subfactor,
  selected,
  showSelectAll = false,
  isFrench = false
}) => {
  const [open, setOpen] = useState(false);

  const checkSelected = (item) => {
    if (subfactor != null) {
      return selected.filter((f) => f.subfactor === item.id).length > 0;
    }

    return selected.filter((f) => f.option === item.id).length > 0;
  };

  return (
    <MenuLine
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
      id={"filters" + title}
    >
      <Line>
        {title}
        <Icon
          name="chevron right"
          style={{ margin: 0, marginTop: 0, fontSize: 10 }}
        />
      </Line>
      <SubMenu open={open}>
        {showSelectAll ? (
          <SubLine
            key={-1}
            onClick={() => onSelect(selections, -1)}
            id={"sub" + title + "-1"}
          >
            Select All
            <CheckContainer selected={false}>
              <Icon name="check" />
            </CheckContainer>
          </SubLine>
        ) : (
          ""
        )}
        {selections.map((item, i) => {
          let show = checkSelected(item);
          return (
            <SubLine
              key={i}
              onClick={() => onSelect(item, item.id)}
              id={"sub" + title + i}
            >
              {isFrench? item.fr? item.fr:item.name:item.name}
              <CheckContainer selected={show}>
                <Icon name="check" />
              </CheckContainer>
            </SubLine>
          );
        })}
      </SubMenu>
    </MenuLine>
  );
};

const MenuLine = styled.div`
  width: 100%;
  padding: 10px 10px;
  position: relative;

  &:hover {
    background-color: #b5b5b5;
    color: rgba(0, 0, 0, 0.87);
  }
`;

const CheckContainer = styled.div`
  display: ${(props) => (props.selected ? "flex" : "none")};
`;

const Line = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  cursor: pointer;
`;

const SubLine = styled.div`
  width: 100%;
  padding: 10px 10px;
  display: flex;
  justify-content: space-between;

  &:hover {
    background-color: #b5b5b5;
    color: rgba(0, 0, 0, 0.87);
  }
`;

const SubMenu = styled.div`
  position: absolute;
  top: 0px;
  left: 200px;
  width: 200px;
  max-height: 300px;
  overflow-y: scroll;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 1px 4px;
  z-index: 99;
  display: ${(props) => (props.open ? "flex" : "none")};
  flex-direction: column;

  cursor: pointer;
`;

const NavBar2 = ({
  structure,
  employee_categories,
  isAdmin,
  subfactor_access,
  sportSelectCb,
  showOnlyPrimary,
  setPreservedState,
}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [access, setAccess] = useState(false);
  const [muted, setMuted] = useState([]);
  const language_hook = new Languages(useLanguageSetting());
  const isFrench = language_hook.is_french();
  const [selectedFilters, setSelected] = useState({});

  const selected = useSelector((state) => state.audit?.filters, shallowEqual);

  const onSelect = (s) => {
    let check = selected.filter(
      (f) => f.id == s.id && f.subfactor == s.subfactor && f.option == s.option
    );

    let _selected;
    if (check.length > 0) {
      _selected = selected.filter((f) => {
        return (
          f.id != s.id || s.subfactor != f.subfactor || f.option != s.option
        );
      });
      if (sportSelectCb) {
        const selectedSubfactor = _selected.filter((f) => f.subfactor);
        const preserved = set_nav(_selected, structure);

        if (setPreservedState) {
          setPreservedState(preserved);
        }
        if (selectedSubfactor.length === 1) {
          sportSelectCb(selectedSubfactor?.[0]?.name?.name);
        } else {
          sportSelectCb(null);
        }
      }
    } else {
      _selected = [...selected, s];
      if (sportSelectCb) {
        const selectedSubfactor = _selected.filter((f) => f.subfactor);
        const preserved = set_nav(_selected, structure);

        if (setPreservedState) {
          setPreservedState(preserved);
        }
        if (selectedSubfactor.length === 1) {
          sportSelectCb(selectedSubfactor?.[0]?.name?.name);
        } else {
          sportSelectCb(null);
        }
      }
    }

    dispatch({
      type: SET_SELECTED_FILTERS,
      payload: _selected,
    });

    set_nav(_selected, structure);

    setMuted([]);
  };

  const selectAll = (s) => {
    //Check to see if the values being passed here have been selected already

    let check = s.filter((f) => {
      return !selected.find(
        (s) =>
          f.id == s.id && f.subfactor == s.subfactor && f.option == s.option
      );
    });
    let _selected;
    setMuted([]);
    if (check.length == s.length) {
      _selected = [...selected, ...s];
    } else if (check.length > 0) {
      _selected = [...selected, ...check];
    } else {
      _selected = [];
    }

    dispatch({
      type: SET_SELECTED_FILTERS,
      payload: _selected,
    });

    const preserved = set_nav(_selected, { ...structure });

    if (setPreservedState) {
      setPreservedState(preserved);
    }
  };

  const set_nav = (new_selected) => {
    // This is to fix an issue where get_survey_structure is being referenced
    // as a array in one instance, and an object in another.
    let useStructure = structure;
    // if its an array use the 0 index
    if (Array.isArray(structure)) {
      useStructure = structure[0];
    }

    let _selected = build_default_State(
      { ...useStructure },
      employee_categories
    );

    new_selected.map((item, i) => {
      if (_selected[item.id].selected) {
        _selected[item.id]["selected"] = false;
        Object.keys(_selected[item.id]).map((s) => {
          if (s != "selected" && s != "priority") {
            _selected[item.id][s].selected = false;
          }
        });
      }

      if (item?.subfactor != null) {
        _selected[item.id][item.option].subfactors[
          item.name.id
        ].selected = true;
        _selected[item.id][item.option].sub = true;
      } else {
        if (_selected[item.id][item.name.id]) {
          _selected[item.id][item.name.id].selected = true;
        } else {
          onSelect(item);
          _selected = build_default_State(
            { ...structure },
            employee_categories
          );
          setRefresh(true);
        }
      }
    });

    dispatch({
      type: SET_NAVIGATION,
      payload: _selected,
    });
    return _selected;
  };

  const sort = (f, s) => {
    const maping = ["primary", "secondary", "tertiary", "personality"];
    let first = maping.findIndex((i) => i == f.priority);
    let second = maping.findIndex((i) => i == s.priority);
    return first - second;
  };

  const getSubFactors = (f) => {
    let subfactors = [];
    f.options.map((item) => {
      if ("subfactors" in item) {
        if (item.subfactors.length > 0) {
          subfactors.push(item);
        }
      }
    });

    return subfactors;
  };

  const findSubfactor = (options, select) => {
    let subfactor = false;
    let option;
    options.map((item) => {
      if (item.subfactors) {
        let subs = item.subfactors.find((f) => f.name == select[0].value);
        if (subs) {
          subfactor = subs;
          option = item;
        }
      }
    });

    return { subfactor, option };
  };

  useEffect(() => {
    if (access) {
      if (checkSelected(access.subfactor)) {
        onSelect(access);
      } else {
        set_nav(selected);
      }
    }
  }, [access,selected]);

  const checkSelected = (sub) => {
    if (selected.length > 0) {
      return selected[0].subfactor != sub;
    }
    return true;
  };

  const mute = (s) => {
    if (!isMuted(s)) {
      let _muted = muted.filter(
        (f) =>
          f.id != s.id || f.subfactor != s.subfactor || f.option != s.option
      );
      return setMuted(_muted);
    }

    setMuted([...muted, s]);
  };

  const isMuted = (s) => {
    return (
      !muted.filter(
        (f) =>
          f.id == s.id && f.subfactor == s.subfactor && f.option == s.option
      ).length > 0
    );
  };

  useEffect(() => {
    if (muted.length > 0) {
      // dispatch({
      // 	type: SET_SELECTED_FILTERS,
      // 	payload: muted,
      // });

      set_nav(muted, { ...structure });
    } else {
      // dispatch({
      // 	type: SET_SELECTED_FILTERS,
      // 	payload: selected,
      // });

      set_nav(selected, { ...structure });
    }
  }, [muted]);

  const filterSubAccess = (f) => {
    return employee_categories.find((e) => e?.mainGroup === f.name) || isAdmin
      ? true
      : false;
  };

  const filterSubs = (f) => {
    return employee_categories.find((e) => e?.value_id === f.id) || isAdmin
      ? true
      : false;
  };

  useEffect(()=>{
    let newStructure = { ...structure };
    if (showOnlyPrimary) {
      newStructure.categories = { ...structure }?.categories?.filter(
        (cat) => cat.priority === "primary"
      );
    }
    setSelected(newStructure)
  },[structure,showOnlyPrimary])


  

  return (
    <Container>
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <Filter onClick={() => setOpen(true)} id="filter" className="filters">
          <ButtonComponent>
            <Image src={X} />
            <TextContainer>{isFrench ? "Filtre" : "Filters"}</TextContainer>
          </ButtonComponent>
          <MenuContainer open={open} id="container">
            {selectedFilters?.categories
              ?.sort((f, s) => sort(f, s))
              .map((item, i) => {
                let subfactors = getSubFactors(item);
                if (!isAdmin && subfactors.length > 0) {
                  if (
                    item.priority == "primary" &&
                    employee_categories?.length < 2 &&
                    employee_categories.length !== 0
                  ) {
                    let { subfactor, option } = findSubfactor(
                      item.options,
                      employee_categories
                    );
                    if (!access) {
                      setAccess({
                        name: subfactor,
                        option: option.id,

                        id: item.id,

                        subfactor: subfactor.id,
                      });
                    }
                    return "";
                  }
                }

                if (subfactors.length > 0 && subfactor_access) {
                  return (
                    <>
                      <MenuItem
                        key={i}
                        title={
                          isFrench
                            ? item.fr_name
                              ? item.fr_name
                              : item.name
                            : item.name
                        }
                        selections={item.options.filter((x) =>
                          filterSubAccess(x)
                        )}
                        selected={selected.filter(
                          (f) => f.id == item.id && f.subfactor == null
                        )}
                        subfactor={null}
                        onSelect={(name, option) =>
                          onSelect({
                            name,
                            option: name.id,

                            id: item.id,

                            subfactor: null,
                          })
                        }
                      />

                      {subfactors
                        .filter((x) => filterSubAccess(x))
                        .map((x, idx) => {
                          return (
                            <MenuItem
                              key={idx}
                              title={isFrench ? (x.fr ? x.fr : x.name) : x.name}
                              selections={x.subfactors.filter((f) =>
                                filterSubs(f)
                              )}
                              subfactor={idx}
                              selected={selected.filter(
                                (f) =>
                                  f.id == item.id &&
                                  f.option == x.id &&
                                  f.subfactor != null
                              )}
                              showSelectAll={true}
                              onSelect={(name, subfactor) => {
                                if (subfactor == -1) {
                                  selectAll(
                                    name.map((f) => {
                                      return {
                                        name: f,
                                        option: x.id,
                                        id: item.id,
                                        subfactor: f.id,
                                      };
                                    })
                                  );
                                } else {
                                  onSelect({
                                    name,
                                    option: x.id,

                                    id: item.id,

                                    subfactor,
                                  });
                                }
                              }}
                            />
                          );
                        })}
                    </>
                  );
                }

                return (
                  <MenuItem
                    key={i}
                    title={
                      isFrench
                        ? item.fr_name
                          ? item.fr_name
                          : item.name
                        : item.name
                    }
                    selections={item.options}
                    selected={selected.filter((f) => f.id == item.id)}
                    subfactor={null}
                    isFrench={isFrench}
                    onSelect={(name, option) =>
                      onSelect({
                        name,
                        option: name.id,
                        id: item.id,

                        subfactor: null,
                      })
                    }
                  />
                );
              })}
          </MenuContainer>
        </Filter>
      </ClickAwayListener>

      {/*<LabelContainer>
        {selected.map((item, i) => {
          let checkMuted = isMuted(item) && muted.length > 0;
          if (access.subfactor && access.subfactor == selected[0].subfactor) {
            return <Label key={i}>{item.name.name}</Label>;
          }
          return (
            <Label key={i} muted={checkMuted}>
              <div onClick={() => mute(item)}>{item.name.name}</div>
              {muted.length == 0 ? (
                <Icon
                  name="delete"
                  style={{ margin: 0, marginTop: -8, zIndex: 10 }}
                  onClick={() => onSelect(item)}
                  id={"label"}
                />
              ) : (
                <Icon
                  name="delete"
                  style={{ margin: 0, marginTop: -8, zIndex: 10 }}
                  id={"label"}
                />
              )}
            </Label>
          );
        })}
      </LabelContainer>*/}
    </Container>
  );
};
export default NavBar2;

const Container = styled.div`
  display: flex;
`;

const Label = styled.div`
  font-family: "Inter", sans-serif;
  background-color: #515151;
  border-radius: 20px;
  transition: background 0.1s ease;
  color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 22px;
  font-size:10px;
  padding:0px 10px;
  color:white;
  opacity: ${(props) => (props.muted ? 0.4 : 1)};
  zindex: -1;

  &:hover {
    background-color: #b5b5b5;
    color: rgba(0, 0, 0, 0.87);
  }
`;

const LabelContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left:-80px;
`;

const Sub = styled.span`
  font-weight: 300;
`;

const IconContainer = styled.div`
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.05);
  height: 100%;
`;

const Filter = styled.div`
  font-family: "Inter", sans-serif;
  margin-right: 5px;
  position: relative;
`;

const ButtonComponent = styled.div`
  background-color: #515151;
  border-radius: 20px;
  transition: background 0.1s ease;
  color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 22px;
  font-size:10px;
  color:white;
  width:76px;
   font-family: "Inter", sans-serif;


  &:hover {
    background-color: #b5b5b5;
    color: rgba(0, 0, 0, 0.87);
  }
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MenuContainer = styled.div`
  position: absolute;
  top: 41px;
  left: 0px;
  width: 200px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 1px 4px;
  z-index: 99;
  display: ${(props) => (props.open ? "flex" : "none")};
  flex-direction: column;
`;

const placeHolderShimmer = keyframes`
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
`;

const LoadingButton = styled.div`
  height: 35px;
  width: 100px;
  animation-duration: 1.8s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: ${placeHolderShimmer};
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #fafafa 8%, #f4f4f4 38%, #fafafa 54%);
  background-size: 1000px 640px;
  margin-bottom: 5px;
  position: relative;
  border-radius: 5px;
`;


const Image = styled.img`
	height:10px;
	margin-right:5px;
`