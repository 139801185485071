import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    margin-top:40px;
`

const TableContainer = styled.div`
  width: 100%;
  margin: 20px auto;
  font-family: 'Raleway', sans-serif;
  max-width: 800px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 15px; /* Creates space between rows */
  background-color: #ffffff;
`;

const TableHeader = styled.th`
  text-align: left;
  padding: 20px 15px;
  font-size: 14px;
  font-weight: 600;
  color: #666D79;
  border-bottom: 1px solid #DFDFDF;
  margin-bottom: 20px;
`;

const TableRow = styled.tr`
  background-color: #fff;
  border: 1px solid #e6e6e6;
  &:hover {
    background-color: #f9f9f9;
  }
`;

const TableData = styled.td`
  padding: 10px;
  text-align: left;
  font-size: 14px;
  color: #4a4a4a;
  vertical-align: middle;

  &:first-child {
    font-weight: bold;
  }

  a {
    color: #1D9A7D;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const ReportButton = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 15px;
  background-color: #eafbf7;
  border-radius: 8px;
  cursor: pointer;
  justify-content: center;
  color: #1D9A7D;
  font-weight: 600;

  &:hover {
    background-color: #dff7f1;
  }

  svg {
    margin-right: 5px;
  }
`;

const Title = styled.div`
    font-size:26px;
    font-weight:bold;
    color:#2A3039;
    margin-bottom:10px;
`

const Description = styled.div`
    margin-bottom:20px;
`

const fakeData = [
  {
    outcome: 'Retention',
    version: 'v1.2',
    date: '2024-09-15',
    predictionPower: '0.85',
    sampleSize: 300,
    status: 'Live',
    reportUrl: '#'
  },
  {
    outcome: 'Innovation',
    version: 'v1.1',
    date: '2024-08-30',
    predictionPower: '0.78',
    sampleSize: 150,
    status: 'Success',
    reportUrl: '#'
  },
  {
    outcome: 'Engagement',
    version: 'v2.0',
    date: '2024-07-20',
    predictionPower: '0.92',
    sampleSize: 450,
    status: 'Live',
    reportUrl: '#'
  }
];

const HistoryTable = () => {
  return (
    <Container>
        
    <TableContainer>
    <Title>
            Custom Predictive Model History
        </Title>

        <Description>
            The history of all custom predictive models created by your organization. For a comprehensive report on the validation and impact of the predictive model download the full data science report.
        </Description>
      <Table>
        <thead>
          <tr>
            <TableHeader>Outcome</TableHeader>
            <TableHeader>Version</TableHeader>
            <TableHeader>Date</TableHeader>
            <TableHeader>Prediction Power (r²)</TableHeader>
            <TableHeader>Sample Size</TableHeader>
          
            <TableHeader>Report</TableHeader>
          </tr>
        </thead>
        <tbody>
          {fakeData.map((item, index) => (
            <TableRow key={index}>
              <TableData>{item.outcome}</TableData>
              <TableData>{item.version}</TableData>
              <TableData>{item.date}</TableData>
              <TableData>{item.predictionPower}</TableData>
              <TableData>{item.sampleSize}</TableData>
              <TableData>
                <ReportButton>
                  <a href={item.reportUrl} target="_blank" rel="noopener noreferrer">
                    Download Report
                  </a>
                </ReportButton>
              </TableData>
            </TableRow>
          ))}
        </tbody>
      </Table>
    </TableContainer>
    </Container>
  );
};

export default HistoryTable;
