import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import {
  Icon,
  Progress,
  Dropdown,
  Tab,
  Loader,
  Checkbox,
  Modal
} from "semantic-ui-react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import Languages from "language/index";
import { useLanguageSetting } from "language/redux_store";
import PlusImg from 'assets/images/nav/plus.svg'
import { SET_SELECTED_QUESTION } from "constants/actions";
import Pic from 'assets/images/UI/blue_doc.svg'
import SurveyVersions from "reports/Audit/VersionControl/index";
import { xgcdDependencies } from "mathjs";
import { title } from "process";


const QuestionDrop = ({ pillarOnly=false,raw_feedback,outcomeQ }) => {
  const language_hook = new Languages(useLanguageSetting());
  const isFrench = language_hook.is_french();
  const [hoveredItem, setHoveredItem] = useState();
  const dispatch = useDispatch();
  const structure = useSelector((state) => state.audit?.question_structure, shallowEqual)
  const questionStructure = useSelector((state) => state.audit?.survey_version, shallowEqual);

  const [open, setOpen] = React.useState(false)

  let ids = 0;
  const setStructure = () => {
    let names = [];
    if (!pillarOnly) {
      names.push({
        title: isFrench ? "Culture Générale" : "Overall Culture",
        level: 0,
        id: ids,
      });
      ids += 1;
    }

    questionStructure.questions.dimensions.map((item, i) => {
      if (!pillarOnly) {
        names.push({
          title: isFrench ? (item.fr ? item.fr : item.title) : item.title,
          level: 1,
          id: ids,
          dimension: i,
        });
        ids += 1;
      }

      if (item.factors?.length > 0) {
        item.factors.map((x, idx) => {
          names.push({
            title: isFrench ? (x.fr ? x.fr : x.title) : x.title,
            level: 2,
            id: ids,
            dimension: i,
            factor: idx,
          });
          ids += 1;
        });
      }
      return null
    });

    if (outcomeQ?.questions?.length > 0) {
      names.push({
        title: "Outcomes",
        level: 1,
      });
    }

    outcomeQ?.questions?.map((item, i) => {
      names.push({
        title: item.name,
        id: item.id,
        level: 3,
        type: "outcome",
      });
    });

    if (questionStructure?.questions.comments?.length > 0) {
      names.push({
        title: "Custom Questions",
        level: 1,
      });
    }

    questionStructure?.questions.comments?.map((item, i) => {
      names.push({
        title: item.question,
        id: i,
        level: 4,
        type: "comment",
      });
    });


    console.log(names)

    return names;
  };

  const [labels, setLables] = useState();
  const [groupedLabels, setGroupedLabels] = useState([]);
  const [parentLabels, setParentLabels] = useState([]);
  const [selected, setSelected] = useState();

  const question = useSelector((state) => state.audit?.selected, shallowEqual);
  


  useEffect(() => {
    console.log(questionStructure)
    if(questionStructure){
        
        setLables(setStructure());
    }
  }, [questionStructure]);

  const select = (d) => {
    setSelected(d);
    dispatch({
      type: SET_SELECTED_QUESTION,
      payload: d,
    });
  };

  function titleExists(arr, titleToCheck) {
    return arr.some(item => item.title === titleToCheck);
}

  useEffect(() => {
    if(labels && (!selected || !titleExists(labels,selected.title) )){
      select(labels[0]);
    }
     

  }, [labels]);

  useEffect(() => {
    if (labels) {
      const groups = getGroupedLabels(labels);
      const labelGroups = Object.keys(groups);
      setGroupedLabels(getGroupedLabels(labels));
      setParentLabels(labelGroups);
    }
  }, [labels]);

  const get_label = () => {
    labels.filter((f) => f.level == question.level && f.id == question.id);
  };

  const getGroupedLabels = (labels) => {

    return labels.reduce((acc, item) => {
      if (item.level === 1) {
        acc[item.title] = { ...item, factors: [] };
      }
      if (item.level === 2) {
        const parent = labels.find(
          (parent) => parent.dimension === item.dimension
        );

        if (parent) {
          acc[parent.title] = acc[parent.title] || [];
          acc[parent.title].factors.push(item);
        }
      }
      if(item.level == 3){

        acc['Outcomes'] = acc['Outcomes'] || [];
        acc['Outcomes'].factors.push(item)
      }

      if(item.level == 4){
        acc['Custom Questions'] = acc['Custom Questions'] || [];
        acc['Custom Questions'].factors.push(item)
      }

      return acc;
    }, []);
  };

  const get_data_points = (factor) => {
    if (structure?.dimensions?.length == 1) {
      return raw_feedback.filter((f) => f.feedback.factor == factor.factor)
        .length;
    }

    if(factor.type == 'outcome'){
      return raw_feedback.filter(f=>f.type == 'outcome' && f.feedback.q == factor.id).length
    }

    if(factor.type == 'comment'){
      return raw_feedback.filter(f=>f.type == 'comments' && f.id == factor.id).length
    }

    return raw_feedback.filter(f=>f.feedback.factor == factor.factor && f.feedback.id == factor.dimension).length
  }

  if(!labels){
    return ''
  }

  return (
    <>
    <Factor>{selected?.title}</Factor>
    <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            closeIcon
            size="medium"
            trigger={<Container>
                <IconImg2 src={PlusImg}/> Select factor
             </Container>}
          >

            <Content>
                <Title>Select a culture factor</Title>
                <div style={{position:'absolute',right:20, top:20}}>
                    <SurveyVersions label={true}/>
                </div>



                    <div style={{marginBottom:20}}>
                    <ItemContainer
                        onMouseOver={() => setHoveredItem(`parent`)}
                        onMouseLeave={() => setHoveredItem("")}
                        onClick={() => select(labels[0])}
                        selected={selected?.title === labels[0].title}
                    >
                                                       
                                    <div style={{width:'90%'}}>
                                        <OptionTitle> All culture factors</OptionTitle>
                                        <OptionDescription>Choose this option to include all of your culture data.</OptionDescription>

                                    </div>
                                 
                                {(selected?.title === labels[0].title) && (
                                <Icon
                                    style={{position:'absolute', right:10 }}
                                    name="check"
                                />
                                )}
                      
                        {/* {selected?.title === labels[0].title && (
                        <Icon
                            style={{ marginTop: -15, paddingLeft: 6 }}
                            name="check"
                        />
                        )} */}
                    </ItemContainer>
                    </div>
                
                {parentLabels.map((item, i) => {
                    const isHovered = hoveredItem === `dimension${i}`;
                    const parentSelected =
                    selected?.title === groupedLabels[item].title;
                    const dimensionSelected = selected?.title === labels[0].title;
                    const total_data_points = groupedLabels[item].factors.reduce((acc, factor) => {
                        return acc + get_data_points(factor)
                    }, 0)

                    if(total_data_points==0){
                        return ""
                    }

                    if(item == 'Outcomes' && raw_feedback.filter(f=>f.type == 'outcome').length == 0){
                      return ''
                    }


                    return (
                        <>
                        <SectionTitle  onClick={() => select(groupedLabels[item])}>{item}</SectionTitle>
                        <AllItems>
                        {groupedLabels[item].factors.map((factor, idx) => {
                        const isSelected = selected?.title === factor.title;
                        const allSelected = selected?.title === labels[0].title;
                        const parentSelected =
                            false

                        const isHovered = hoveredItem === `questions${idx}`;
                        const data_points = get_data_points(factor)

                        if(data_points==0){
                            return ""
                        }
                        return (
                            <ItemContainer
                                onClick={() => select(factor)}
                                onMouseOver={() =>
                                setHoveredItem(`questions${idx}`)
                                }
                                onMouseLeave={() => setHoveredItem("")}
                                selected={isSelected || parentSelected}
                                fullWidth={factor.type=='comment'}
                            >
                                {/* <div style={{height:'100%'}}>
                                <Circle  selected={isSelected || parentSelected}><IMG src={Pic}/></Circle>
                                </div> */}
                               
                                    <div style={{width:'90%'}}>
                                        <OptionTitle question={factor.type=="comment"}>{factor.title} <Data>{data_points} data points</Data></OptionTitle>
                                        
                                        {/* <OptionDescription>A culture where individuals feel they can speak up, express their ideas, and take risks without fear of punishment or humiliation.</OptionDescription> */}

                                    </div>
                                 
                                {(isSelected || parentSelected) && (
                                <Icon
                                    style={{position:'absolute', right:10 }}
                                    name="check"
                                />
                                )}
                            </ItemContainer>
                        );
                        })}
                    </AllItems>
                    </>
                    );
                })}
            </Content>

            <Bottom>
                <Button onClick={() => setOpen(false)}>Select</Button>
            </Bottom>
   
            
    </Modal>

    </>
  );
};

export default QuestionDrop;

const Container = styled.div`
    color:#2D70E2;
    font-family: Raleway;
font-size: 14px;
font-style: normal;
line-height: 150%;
width:130px;
display:flex;
align-items:center;
cursor:pointer;
margin-left:20px;
`

const AllItems = styled.div`
    display:flex;
    width:100%;
    flex-wrap:wrap;
    margin-bottom:20px;
`

const SectionTitle = styled.div`
font-family: Raleway;
font-size: 18px;
font-style: normal;
font-weight: 600;
color:#2A3039;
margin-bottom:10px;
cursor:pointer;
`

const Title = styled.div`
font-family: 'Raleway';
font-style: normal;
font-weight: 700;
font-size: 24px;
color: #2A3039;
margin-bottom:20px;
`

const Button = styled.button`
background: #2D70E2;
border-radius: 8px;
font-family: 'Raleway';
font-style: normal;
font-weight: 600;
font-size: 14px;
color: #FFFFFF;
border:none;
display:flex;
align-items:center;
justify-content:center;
padding: 12px 24px;
cursor:pointer;
`

const First = styled.div`
  display: flex;
  text-transform: uppercase;
  height: 30px;
  padding-left: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  display: flex;
  color: #414141;
  margin-top:-10px;
`;

const CheckboxStyled = styled(Checkbox)`
  margin-left: 10px;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 20px;
  border:${props=>props.selected? '1px solid #2D70E2':'1px solid #E5E5E5'};
  background-color:${props=>props.selected? '#2D70E2':'#fff'};
  color:${props=>props.selected? '#fff':'#2A3039'};
  width:${props=>props.fullWidth? '100%':'calc(50% - 20px)'};
  margin-right:10px;
  margin-bottom:10px;
  cursor:pointer;
  position:relative;
`;


const Circle = styled.div`
    width:40px;
    height:40px;
    border-radius: 8px;
    background: ${props=>props.selected? '#2D70E2':'#EBF1FD'};
    margin-right:10px;
    min-width:40px;
    display:flex;
    align-items:center;
    justify-content:center;
`

const OptionTitle = styled.div`
    font-family: 'Raleway';
    font-style: normal;
    font-weight: ${props=>props.question?'400':'600'};
    font-size: 14px;
    margin-bottom:5px;
    display:flex;
    line-height:1.5;
    align-items:${props=>props.question?'flex-start':'center'};
    flex-direction: ${props=>props.question? "column":"row"};

`

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
`;

const FactorSection = styled.div`
  margin-left: 20px;
  padding-right: 10px;
  margin-right: 40px;
`;

const SelectItem = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 16px;
`;

const IconImg2 = styled.img`
    width:20px;
    margin-right:4px;
`

const Content = styled.div`
    padding:20px;
`


const IMG = styled.img`
    width:20px;

`

const OptionDescription = styled.div`
    font-family: 'Raleway';

    font-size: 12px;
    margin-bottom:5px;
    line-height:1.4;
`

const Factor = styled.div`

font-size: 16px;
font-style: normal;
font-weight: 600;
`
const Bottom = styled.div`
    display:flex;
    justify-content:flex-end;
    margin-top:20px;
    width:100%;
    border-top:1px solid #E5E5E5;
    padding:20px;
`

const Data = styled.span`
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height:1.4;
    color:#2D70E2;
    margin-left:10px;
`