import axios from "axios";
import urlconfig from "../_config";
const VERSION = "v1";

// Auth APIS START
export const LOAD_USER = () => () => {
  return axios.get(`${urlconfig.loginapi}/${VERSION}/profile/`, tokenConfig());
};

export const LOGIN_USER = (payload) => {
  const response = axios.post(`${urlconfig.loginapi}/sign-in`, payload, config);
  return response;
};

export async function REGISTER_USER(data) {
  // console.log(data)
  return await axios.post(`${urlconfig.loginapi}/user/sign-up`, data);
}

export async function REGISTER_USER_TOKEN(data) {
  // console.log(data)
  return await axios.post(`${urlconfig.loginapi}/user/token-sign-up`, data);
}
// google auth
export const GOOGLE_AUTH = (payload) => {
  const response = axios.post(
    `${urlconfig.loginapi}/google-auth/sign-in`,
    payload,
    config
  );
  return response;
};

export async function REGISTER_GOOGLE_AUTH(data) {
  // console.log(data)
  return await axios.post(`${urlconfig.loginapi}/google-auth/sign-up`, data);
}

export async function REGISTER_GOOGLE_AUTH_TOKEN(data) {
  // console.log(data)
  return await axios.post(
    `${urlconfig.loginapi}/google-auth/token-sign-up`,
    data
  );
}
// google auth

export async function REGISTER_AMBASSADOR(data) {
  // console.log(data)
  return await axios.post(`${urlconfig.loginapi}/create/ambassador`, data);
}

export const LOGOUT_USER = (payload) => {
  axios.post(`${urlconfig.loginapi}/logout`, payload, tokenConfig());
};

export async function UPDATE_ACCOUNT_DEFAULT(payload) {
  // console.log(data)
  return await axios.post(
    `${urlconfig.loginapi}/change-account-default`,
    payload,
    tokenConfig()
  );
}

export const FORGOT_PASSWORD = async (payload) => {
  const response = await axios.post(
    `${urlconfig.loginapi}/forgot-password`,
    payload,
    config
  );
  // console.log(response)
  let { data } = response;
  // console.log(data)
  return data;
};

export const RESET_PASSWORD = async (payload) => {
  let res = await axios.post(
    `${urlconfig.loginapi}/reset-password`,
    payload,
    config
  );
  return res;
};

//user-password-change/
export const CHANGE_PASSWORD = (payload) =>
  axios.post(`${urlconfig.loginapi}/change-password`, payload, tokenConfig());

export const VALIDATE_EMAIL = (payload) =>
  axios.post(`${urlconfig.loginapi}/validate-email`, payload, config);

export const VALIDATE_TOKEN = (payload) =>
  axios.post(`${urlconfig.loginapi}/validate-signup-token`, payload);

// Auth APIS END
// Headers
const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

const tokenConfig = () => {
  const token = localStorage.getItem("token");
  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  // If token, add to headers config
  if (token) {
    config.headers["Authorization"] = `Token ${token}`;
  }
  return config;
};

const bearerConfig = () => {
  const token = localStorage.getItem("token");
  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  // If token, add to headers config
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
};

const chatTokenConfig = () => {
  const chatToken = localStorage.getItem("chat_token");
  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  // If token, add to headers config
  if (chatToken) {
    config.headers["Authorization"] = `Bearer ${chatToken}`;
  }
  return config;
};

const tokenMl = (token) => {
  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  // If token, add to headers config
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
};

// Auth APIS END

// Error Alert
// error-alert
export const LOG_ERROR = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/error-alert/`,
    payload,
    tokenConfig()
  );

//
export const GET_SURVEY_QUESTIONS = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/survey-question/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const FETCH_SURVEY_QUESTION = ({ id }) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/survey-question/${id}/`,
    tokenConfig()
  );
export const ADD_SURVEY_QUESTION = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/survey-question/`,
    payload,
    tokenConfig()
  );

export const EDIT_SURVEY_QUESTION = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/survey-question/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const DELETE_SURVEY_QUESTION = (id) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/survey-question/${id}/`,
    tokenConfig()
  );

//
// employee-list-legacy/
export const GET_EMPLOYEES = (payload) => {
  return axios.get(
    `${urlconfig.appapi}/${VERSION}/employee-list-legacy/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );
};

// export const GET_EMPLOYEES = (payload) => {
//   return axios.get(
//     `${urlconfig.appapi}/${VERSION}/employee/${payload ? `?${payload}` : ""}`,
//     tokenConfig()
//   );
// };

export const GET_INACTIVE_EMPLOYEES = (payload) => {
  return axios.get(
    `${urlconfig.appapi}/${VERSION}/employee/inactive_list/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );
};

export const GET_EMPLOYEE_SELECT_LIST = (payload) => {
  return axios.get(
    `${urlconfig.appapi}/${VERSION}/employee/selectlist/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );
};

export const GET_EMPLOYEE_LIST = (payload) => {
  return axios.get(
    `${urlconfig.appapi}/${VERSION}/employee/quicklist/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );
};

export const GET_SAFE_EMPLOYEE_LIST = () =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/employee/selectlist`,
    tokenConfig()
  );

export const FETCH_EMPLOYEE = ({ id }) =>
  axios.get(`${urlconfig.appapi}/${VERSION}/employee/${id}/`, tokenConfig());

export const ADD_EMPLOYEE = (employee) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/employee/`,
    employee,
    tokenConfig()
  );

export const UPDATE_EMPLOYEE_STATUS = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/employee/update_status/`,
    payload,
    tokenConfig()
  );
// toggle of enable disable login get login
export const REGISTER_EMPLOYEE_CULTURE = (employee) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/employee/?add-new=true&source=culture`,
    employee,
    tokenConfig()
  );
// role id gets login source is invalid for this for now
export const REGISTER_EMPLOYEE = (employee) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/employee/?add-new=true&source=team`,
    employee,
    tokenConfig()
  );

export const SIGNUP_TOKEN = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/new-signup-token/`,
    payload,
    tokenConfig()
  );
//
export const EDIT_EMPLOYEE = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/employee/${payload.id}/`,
    payload,
    tokenConfig()
  );
export const EDIT_EMPLOYEE_AUDIT = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/employee/${payload.id}/?source=culture`,
    payload,
    tokenConfig()
  );

export const DELETE_EMPLOYEE = ({ id }) =>
  axios.delete(`${urlconfig.appapi}/${VERSION}/employee/${id}/`, tokenConfig());

export const ADD_EMPLOYEE_PIC = (payload) => {
  const formData = new FormData();
  formData.append("id", payload.id);
  formData.append("picture", payload.picture);
  return axios.patch(
    `${urlconfig.appapi}/${VERSION}/upload-emp-pic/${payload.id}/`,
    formData,
    tokenConfig()
  );
};

export const GET_EMPLOYEE_REPORTS = (payload) => {
  return axios.get(
    `${urlconfig.appapi}/${VERSION}/employee-report/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );
};
//

export const GET_EMPLOYEE_PERMISSIONS = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/employee-permission/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const FETCH_EMPLOYEE_PERMISSION = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/employee-permission/${payload.id}/${
      payload?.query ? `?${payload?.query}` : ""
    }`,
    tokenConfig()
  );

export const ADD_EMPLOYEE_PERMISSION = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/employee-permission/${
      payload?.query ? `?${payload?.query}` : ""
    }`,
    payload.body,
    tokenConfig()
  );

export const EDIT_EMPLOYEE_PERMISSION = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/employee-permission/${payload?.body?.id}/${
      payload?.query ? `?${payload?.query}` : ""
    }`,
    payload.body,
    tokenConfig()
  );

export const DELETE_EMPLOYEE_PERMISSION = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/employee-permission/${id}/`,
    tokenConfig()
  );

//
export const GET_INDUSTRY_BENCHMARK = (payload) => {
  return axios.get(
    `${urlconfig.appapi}/${VERSION}/industry-benchmark/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );
};

//
export const GET_ORGANIZATIONS = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/organization/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const FETCH_ORGANIZATION = ({ id }) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/organization/${id}/`,
    tokenConfig()
  );
export const ADD_ORGANIZATION = (organization) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/organization/`,
    organization,
    tokenConfig()
  );

export const EDIT_ORGANIZATION = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/organization/${payload.id}/`,
    payload,
    tokenConfig()
  );
//

export const ADD_ORGANIZATION_LOGO = (payload) => {
  const formData = new FormData();
  formData.append("id", payload.id);
  formData.append("logo", payload.logo);
  return axios.post(
    `${urlconfig.appapi}/${VERSION}/upload-org-logo/`,
    formData,
    tokenConfig()
  );
};

export const DELETE_ORGANIZATION = (id) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/organization/${id}/`,
    tokenConfig()
  );

export const GET_DEPARTMENTS = () =>
  axios.get(`${urlconfig.appapi}/${VERSION}/department/`, tokenConfig());

export const FETCH_DEPARTMENT = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/department/${payload.id}/`,
    tokenConfig()
  );

export const ADD_DEPARTMENT = (department) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/department/`,
    department,
    tokenConfig()
  );

export const EDIT_DEPARTMENT = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/department/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const DELETE_DEPARTMENT = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/department/${id}/`,
    tokenConfig()
  );

export const GET_TEAMS = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/team/${payload ? `?${payload}` : ""}`,
    tokenConfig()
  );

export const FETCH_TEAM = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/team/${payload.id}/`,
    tokenConfig()
  );
export const ADD_TEAM = (payload) =>
  axios.post(`${urlconfig.appapi}/${VERSION}/team/`, payload, tokenConfig());

export const EDIT_TEAM = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/team/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const DELETE_TEAM = ({ id }) =>
  axios.delete(`${urlconfig.appapi}/${VERSION}/team/${id}/`, tokenConfig());

// TEAM ACCESS START

export const GET_TEAM_ACCESS = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/team-access/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const FETCH_TEAM_ACCESS = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/team-access/${payload.id}/`,
    tokenConfig()
  );
export const ADD_TEAM_ACCESS = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/team-access/`,
    payload,
    tokenConfig()
  );

export const EDIT_TEAM_ACCESS = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/team-access/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const DELETE_TEAM_ACCESS = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/team-access/${id}/`,
    tokenConfig()
  );

// TEAM ACCESS END

// Global Schedule TEAM TYPE
export const GET_TEAM_TYPES = () =>
  axios.get(`${urlconfig.appapi}/${VERSION}/team-type/`, tokenConfig());

export const FETCH_TEAM_TYPE = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/team-type/${payload.id}/`,
    tokenConfig()
  );

// Global Schedule TEAM TYPE

// employee-role START
export const GET_EMPLOYEE_ROLES = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/employee-role/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const FETCH_EMPLOYEE_ROLE = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/employee-role/${payload.id}/`,
    tokenConfig()
  );

export const EDIT_EMPLOYEE_ROLE = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/employee-role/${payload.id}/`,
    payload,
    tokenConfig()
  );
// employee role STOP

export const GET_GLOBAL_SCHEDULES = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/global-schedule/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const FETCH_GLOBAL_SCHEDULE = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/global-schedule/${payload.id}/`,
    tokenConfig()
  );

export const ADD_GLOBAL_SCHEDULE = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/global-schedule/`,
    payload,
    tokenConfig()
  );

export const EDIT_GLOBAL_SCHEDULE = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/global-schedule/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const SCHEDULE = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/global-schedule/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const DELETE_GLOBAL_SCHEDULE = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/global-schedule/${id}/`,
    tokenConfig()
  );
// Global Schedule END

// Global Schedule Frequency START
export const GET_GLOBAL_SCHEDULE_FREQUENCIES = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/global-schedule-frequency/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const FETCH_GLOBAL_SCHEDULE_FREQUENCY = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/global-schedule-frequency/${payload.id}/`,
    tokenConfig()
  );

export const ADD_GLOBAL_SCHEDULE_FREQUENCY = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/global-schedule-frequency/`,
    payload,
    tokenConfig()
  );

export const EDIT_GLOBAL_SCHEDULE_FREQUENCY = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/global-schedule-frequency/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const DELETE_GLOBAL_SCHEDULE_FREQUENCY = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/global-schedule-frequency/${id}/`,
    tokenConfig()
  );
// Global Schedule Frequency END

// SURVEY SETTING LIST START
export const GET_SURVEY_SETTING_LIST = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/survey-setting-list/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const FETCH_SURVEY_SETTING_LIST = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/survey-setting-list/${payload.id}/`,
    tokenConfig()
  );

export const ADD_SURVEY_SETTING_LIST = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/survey-setting-list/`,
    payload,
    tokenConfig()
  );

export const EDIT_SURVEY_SETTING_LIST = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/survey-setting-list/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const DELETE_SURVEY_SETTING_LIST = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/survey-setting-list/${id}/`,
    tokenConfig()
  );
// SURVEY SETTING LIST END

// teams in survey-compliance START

//
export const GET_ONBOARDINGS = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/onboarding/${payload ? `?${payload}` : ""}`,
    tokenConfig()
  );

export const FETCH_ONBOARDING = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/onboarding/${payload.id}/`,
    tokenConfig()
  );
export const ADD_ONBOARDING = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/onboarding/`,
    payload,
    tokenConfig()
  );

export const EDIT_ONBOARDING = (payload) => {
  const onbord = axios.patch(
    `${urlconfig.appapi}/${VERSION}/onboarding/${payload.id}/`,
    payload,
    tokenConfig()
  );
  // console.log(onbord)
  return onbord;
};

export const DELETE_ONBOARDING = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/onboarding/${id}/`,
    tokenConfig()
  );
//

export const GET_SURVEY_COMPLIANCES = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/survey-compliance/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const FETCH_SURVEY_COMPLIANCE = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/survey-compliance/${payload.id}/`,
    tokenConfig()
  );

export const ADD_SURVEY_COMPLIANCE = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/survey-compliance/`,
    payload,
    tokenConfig()
  );

export const EDIT_SURVEY_COMPLIANCE = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/survey-compliance/${payload.id}/${
      payload.query ? `?${payload.query}` : ""
    }`,
    payload,
    tokenConfig()
  );

export const DELETE_SURVEY_COMPLIANCE = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/survey-compliance/${id}/`,
    tokenConfig()
  );

// teams in survey-compliance END

// teams in schedule START
export const GET_TEAMS_IN_SCHEDULES = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/teams-in-schedule/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const FETCH_TEAMS_IN_SCHEDULE = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/teams-in-schedule/${payload.id}/`,
    tokenConfig()
  );

export const ADD_TEAMS_IN_SCHEDULE = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/teams-in-schedule/`,
    payload,
    tokenConfig()
  );

export const EDIT_TEAMS_IN_SCHEDULE = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/teams-in-schedule/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const DELETE_TEAMS_IN_SCHEDULE = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/teams-in-schedule/${id}/`,
    tokenConfig()
  );
// teams in schedule END

// Leaders In Global Schedule START
export const GET_LEADERS_IN_SCHEDULES = () =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/leaders-in-schedule/`,
    tokenConfig()
  );

export const FETCH_LEADERS_IN_SCHEDULE = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/leaders-in-schedule/${payload.id}/`,
    tokenConfig()
  );

export const ADD_LEADERS_IN_SCHEDULE = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/leaders-in-schedule/`,
    payload,
    tokenConfig()
  );

export const EDIT_LEADERS_IN_SCHEDULE = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/leaders-in-schedule/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const DELETE_LEADERS_IN_SCHEDULE = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/leaders-in-schedule/${id}/`,
    tokenConfig()
  );
// Leaders In Global Schedule END

// Leader Survey Participants START
export const GET_LEADER_SURVEY_PARTICIPANTS = () =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/leader-survey-participants/`,
    tokenConfig()
  );

export const FETCH_LEADER_SURVEY_PARTICIPANT = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/leader-survey-participants/${payload.id}/`,
    tokenConfig()
  );

export const ADD_LEADER_SURVEY_PARTICIPANT = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/leader-survey-participants/`,
    payload,
    tokenConfig()
  );

export const EDIT_LEADER_SURVEY_PARTICIPANT = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/leader-survey-participants/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const DELETE_LEADER_SURVEY_PARTICIPANT = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/leader-survey-participants/${id}/`,
    tokenConfig()
  );
// Leader Survey Participants END

// Personality Teams START
export const GET_PERSONALITY_TEAMS = () =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/teams-in-personality/`,
    tokenConfig()
  );

export const FETCH_PERSONALITY_TEAM = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/teams-in-personality/${payload.id}/`,
    tokenConfig()
  );

export const ADD_PERSONALITY_TEAM = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/teams-in-personality/`,
    payload,
    tokenConfig()
  );

export const EDIT_PERSONALITY_TEAM = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/teams-in-personality/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const DELETE_PERSONALITY_TEAM = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/teams-in-personality/${id}/`,
    tokenConfig()
  );
// Personality Teams END

// Personality Participants START
export const GET_PERSONALITY_PARTICIPANTS = () =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/participants-in-personality/`,
    tokenConfig()
  );

export const FETCH_PERSONALITY_PARTICIPANT = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/participants-in-personality/${payload.id}/`,
    tokenConfig()
  );

export const ADD_PERSONALITY_PARTICIPANT = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/participants-in-personality/`,
    payload,
    tokenConfig()
  );

export const EDIT_PERSONALITY_PARTICIPANT = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/participants-in-personality/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const DELETE_PERSONALITY_PARTICIPANT = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/participants-in-personality/${id}/`,
    tokenConfig()
  );
// Personality Participants ENDCANCEL_SUBSCRIPTION

// PAYMENT_METHODS START
export const GET_STRIPE_TAX_RATES = () =>
  axios.get(`${urlconfig.appapi}/${VERSION}/tax-rates/`, tokenConfig());

// PAYMENT_METHODS START
export const GET_PAYMENT_METHODS = () =>
  axios.get(`${urlconfig.appapi}/${VERSION}/payment-method/`, tokenConfig());

export const FETCH_PAYMENT_METHOD = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/payment-method/${payload.id}/`,
    tokenConfig()
  );

export const ADD_PAYMENT_METHOD = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/payment-method/`,
    payload,
    tokenConfig()
  );

export const EDIT_PAYMENT_METHODS = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/payment-method/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const DELETE_PAYMENT_METHOD = ({ id }) => {
  return axios.delete(
    `${urlconfig.appapi}/${VERSION}/payment-method/${id}/`,
    tokenConfig()
  );
};

// DEBRRIEF_EMAIL START
export const GET_DEBRIEF_EMAILS = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/debrief-email/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const FETCH_DEBRIEF_EMAIL = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/debrief-email/${payload.id}/`,
    tokenConfig()
  );

export const ADD_DEBRIEF_EMAIL = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/debrief-email/`,
    payload,
    tokenConfig()
  );

export const EDIT_DEBRIEF_EMAIL = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/debrief-email/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const DELETE_DEBRIEF_EMAIL = ({ id }) => {
  return axios.delete(
    `${urlconfig.appapi}/${VERSION}/debrief-email/${id}/`,
    tokenConfig()
  );
};

// Ambassador START

export const GET_AMBASSADORS = () =>
  axios.get(`${urlconfig.appapi}/${VERSION}/ambassador/`, tokenConfig());

export const FETCH_AMBASSADOR = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/ambassador/${payload.id}/`,
    tokenConfig()
  );

export const ADD_AMBASSADOR = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/ambassador/`,
    payload,
    tokenConfig()
  );

export const ADD_AMBASSADOR_PIC = (payload) => {
  const formData = new FormData();
  formData.append("id", payload.id);
  formData.append("picture", payload.picture);
  return axios.patch(
    `${urlconfig.appapi}/${VERSION}/upload-ambassador-pic/${payload.id}/`,
    formData,
    tokenConfig()
  );
};

export const EDIT_AMBASSADOR = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/ambassador/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const DELETE_AMBASSADOR = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/ambassador/${id}/`,
    tokenConfig()
  );

export const VALIDATE_AMBASSADOR = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/validate-ambassador/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );
// Ambassador END

// File Upload Start

export const GET_FILE_UPLOADS = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/file-upload/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const FETCH_FILE_UPLOAD = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/file-upload/${payload.id}/`,
    tokenConfig()
  );

// export const ADD_FILE_UPLOAD = (payload) =>
//   axios.post(
//     `${urlconfig.appapi}/${VERSION}/file-upload/`,
//     payload,
//     tokenConfig()
//   );

export const ADD_FILE_UPLOAD = (payload) => {
  // console.log(payload)
  const formData = new FormData();
  formData.append("name", payload.name);
  formData.append("organization", payload.organization);
  formData.append("data_file", payload.data_file);
  formData.append("file_type", payload.file_type ? payload.file_type : 1);
  return axios.post(
    `${urlconfig.appapi}/${VERSION}/file-upload/`,
    formData,
    tokenConfig()
  );
};

export const EDIT_FILE_UPLOAD = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/file-upload/${payload.id}/${
      payload.query ? `?${payload.query}` : ""
    }`,
    payload,
    tokenConfig()
  );

export const DELETE_FILE_UPLOAD = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/file-upload/${id}/`,
    tokenConfig()
  );

// file Upload End
// survey-structure

// survey-structure Start
export const GET_SURVEY_STRUCTURES = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/survey-structure/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const GET_SURVEY_STRUCTURE_SELECT = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/survey-structure/selectlist/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const FETCH_SURVEY_STRUCTURE = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/survey-structure/${payload.id}/`,
    tokenConfig()
  );

export const COPY_SURVEY_STRUCTURE = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/duplicate-survey-structure/`,
    payload,
    tokenConfig()
  );

export const ADD_SURVEY_STRUCTURE = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/survey-structure/`,
    payload,
    tokenConfig()
  );

export const EDIT_SURVEY_STRUCTURE = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/survey-structure/${payload.id}/${
      payload.query ? `?${payload.query}` : ""
    }`,
    payload,
    tokenConfig()
  );

export const DELETE_SURVEY_STRUCTURE = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/survey-structure/${id}/`,
    tokenConfig()
  );

// survey-structure End

// employee-category Start
export const GET_EMPLOYEE_CATEGORIES = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/employee-category/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const FETCH_EMPLOYEE_CATEGORY = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/employee-category/${payload.id}/`,
    tokenConfig()
  );

export const ADD_EMPLOYEE_CATEGORY = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/employee-category/`,
    payload,
    tokenConfig()
  );

export const EDIT_EMPLOYEE_CATEGORY = (payload) => {
  // console.log(payload)

  return axios.patch(
    `${urlconfig.appapi}/${VERSION}/employee-category/${payload.id}/${
      payload.query ? `?${payload.query}` : ""
    }`,
    payload,
    tokenConfig()
  );
};

export const DELETE_EMPLOYEE_CATEGORY = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/employee-category/${id}/`,
    tokenConfig()
  );

// employee-category End

// PAYMENT_METHODS END
// STRIPE PAYMENT API
//

export const GET_NEXT_INVOICE = () =>
  axios.get(`${urlconfig.payment}/${VERSION}/next-invoice/`, tokenConfig());
// p
export const GET_STRIPE_PAYMENT_METHODS = (payload) =>
  axios.post(
    `${urlconfig.payment}/${VERSION}/stripe-payment-methods/`,
    payload,
    tokenConfig()
  );

export const CANCEL_SUBSCRIPTION = (payload) =>
  axios.post(
    `${urlconfig.payment}/${VERSION}/cancel-subscription/`,
    payload,
    tokenConfig()
  );

export const UPDATE_SUBSCRIPTION = (payload) =>
  axios.post(
    `${urlconfig.payment}/${VERSION}/subscription/`,
    payload,
    tokenConfig()
  );

export const UPGRADE_PLAN = (payload) =>
  axios.post(
    `${urlconfig.payment}/${VERSION}/subscription/`,
    payload,
    tokenConfig()
  );

export const DOWNGRADE_PLAN = (payload) =>
  axios.post(
    `${urlconfig.payment}/${VERSION}/subscription/`,
    payload,
    tokenConfig()
  );
// STRIPE PAYMENT API
// SUBSCRIPTION START
export const GET_SUBSCRIPTIONS = () =>
  axios.get(`${urlconfig.appapi}/${VERSION}/subscription/`, tokenConfig());

export const FETCH_SUBSCRIPTION = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/subscription/${payload.id}/`,
    tokenConfig()
  );

export const ADD_SUBSCRIPTION = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/subscription/`,
    payload,
    tokenConfig()
  );

export const EDIT_SUBSCRIPTION = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/subscription/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const DELETE_SUBSCRIPTION = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/subscription/${id}/`,
    tokenConfig()
  );
// SUBSCRIPTION END

// debrief schedule START
export const GET_AUDIT_ELIGIBLE = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/culture-audit-eligible/?${payload}`,
    tokenConfig()
  );
// adduser

export const GET_DEBRIEF_SCHEDULES = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/debrief-schedule/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );
// debrief-schedule-reports
// export const GET_CULTURE_AUDIT_REPORTS = (payload) =>
//   axios.get(
//     `${urlconfig.appapi}/${VERSION}/culture-audit-reports/${payload ? `?${payload}` : ""
//     }`,
//     tokenConfig()
//   );

// export const GET_CULTURE_AUDIT_REPORTS = (payload) =>
//   axios.get(
//     `${urlconfig.appapi}/${VERSION}/culture-audit-reports-filtered/${
//       payload ? `?${payload}` : ""
//     }`,
//     tokenConfig()
//   );

  export const GET_CULTURE_AUDIT_REPORTS = (payload) =>
    axios.get(
      `${urlconfig.reportApi}/report/culture-audit${
        payload ? `?${payload}` : ""
      }`,
      bearerConfig()
    );
// not functional as of now
// export const GET_CULTURE_AUDIT_REPORTS_PAGINATED = (payload) =>
// axios.get(
//   `${urlconfig.appapi}/${VERSION}/culture-audit-reports-filtered-paginated/${payload ? `?${payload}` : ""
//   }`,
//   tokenConfig()
// );

export const FETCH_DEBRIEF_SCHEDULE = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/debrief-schedule/${payload.id}/`,
    tokenConfig()
  );

// debrief-schedule/extendedtime/
export const ADD_DEBRIEF_SCHEDULE_EXTENDED_TIME = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/debrief-schedule/extendedtime/`,
    payload,
    tokenConfig()
  );

// brief-token/adduser/
export const ADD_DEBRIEF_SCHEDULE_ADD_USER = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/brief-token/adduser/`,
    payload,
    tokenConfig()
  );

export const ADD_DEBRIEF_SCHEDULE = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/debrief-schedule/`,
    payload,
    tokenConfig()
  );

export const EDIT_DEBRIEF_SCHEDULE = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/debrief-schedule/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const DELETE_DEBRIEF_SCHEDULE = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/debrief-schedule/${id}/`,
    tokenConfig()
  );
// debrief schedule END

// debrief response START
export const GET_DEBRIEF_RESPONSES = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/debrief-response/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const FETCH_DEBRIEF_RESPONSE = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/debrief-response/${payload.id}/`,
    tokenConfig()
  );

export const ADD_DEBRIEF_RESPONSE = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/debrief-response/`,
    payload,
    tokenConfig()
  );

export const EDIT_DEBRIEF_RESPONSE = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/debrief-response/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const DELETE_DEBRIEF_RESPONSE = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/debrief-response/${id}/`,
    tokenConfig()
  );
// debrief response END

// debrief token START
export const GET_DEBRIEF_TOKENS = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/brief-token/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const FETCH_DEBRIEF_TOKEN = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/brief-token/${payload.id}/`,
    tokenConfig()
  );

export const ADD_DEBRIEF_TOKEN = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/brief-token/`,
    payload,
    tokenConfig()
  );

export const EDIT_DEBRIEF_TOKEN = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/brief-token/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const DELETE_DEBRIEF_TOKEN = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/brief-token/${id}/`,
    tokenConfig()
  );
// debrief token END

// Survey Reports
export const GET_PERSONALITY_REPORTS = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/personality/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const FETCH_PERSONALITY_REPORT = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/personality/${payload.id}/`,
    tokenConfig()
  );

export const GET_CULTURE_REPORTS = (payload) => {
  return axios.get(
    `${urlconfig.appapi}/${VERSION}/culture/${payload ? `?${payload}` : ""}`,
    tokenConfig()
  );
};

export const FETCH_CULTURE_REPORT = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/culture/${payload.id}/`,
    tokenConfig()
  );

export const UPDATE_CULTURE_COMMENTS = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/add-culture-comments/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const GET_MOOD_REPORTS = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/mood/${payload ? `?${payload}` : ""}`,
    tokenConfig()
  );

export const FETCH_MOOD_REPORT = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/mood/${payload.id}/`,
    tokenConfig()
  );

//
export const GET_CULTURE_ORG_REPORTS = (payload) => {
  // console.log(payload)
  return axios.get(
    `${urlconfig.appapi}/${VERSION}/culture-org/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );
};

export const FETCH_CULTURE_ORG_REPORT = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/culture-org/${payload.id}/`,
    tokenConfig()
  );

//

export const GET_MOOD_ORG_REPORTS = (payload) => {
  // console.log(payload)
  return axios.get(
    `${urlconfig.appapi}/${VERSION}/mood-org/${payload ? `?${payload}` : ""}`,
    tokenConfig()
  );
};

export const FETCH_MOOD_ORG_REPORT = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/mood-org/${payload.id}/`,
    tokenConfig()
  );

//

export const GET_LEADERSHIP_REPORTS = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/leadership/${payload ? `?${payload}` : ""}`,
    tokenConfig()
  );

export const FETCH_LEADERSHIP_REPORT = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/leadership/${payload.id}/`,
    tokenConfig()
  );

//
export const CREATE_PAYMENT_INTENT = (payload) => {
  return axios.post(
    `${urlconfig.appapi}/${VERSION}/create-payment-intent/`,
    payload,
    tokenConfig()
  );
};

// price
export const GET_PRICES = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/price/${payload ? `?${payload}` : ""}`,
    tokenConfig()
  );

export const FETCH_PRICE = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/price/${payload.id}/`,
    tokenConfig()
  );
// price

export const VALIDATE_EMPLOYEE = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/validate-employee/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const VALIDATE_ORGANIZATION = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/validate-organization/${
      payload ? `?${payload}` : ""
    }`
  );

export const VALIDATE_ORG_EMP_EMAIL = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/org-email-list/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

// Survey Reports validate-token/

export const PRE_VALIDATE_EMAIL = (payload) => {
  return axios.get(
    `${urlconfig.appapi}/${VERSION}/validate-pre-login/${
      payload ? `?token=${payload.token}` : ""
    }`
  );
};

// sign up token START
export const GET_SIGNUP_TOKENS = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/signup-token/${payload ? payload : ""}`,
    tokenConfig()
  );
export const DELETE_SIGNUP_TOKEN = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/signup-token/${id}/`,
    tokenConfig()
  );
// sign up token END
// survey token START
export const GET_SURVEY_TOKENS = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/survey-token/${payload ? payload : ""}`,
    tokenConfig()
  );
export const DELETE_SURVEY_TOKEN = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/survey-token/${id}/`,
    tokenConfig()
  );
// survey token END
// survey token START
export const GET_SURVEY_RESPONSES = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/survey-response/${payload ? payload : ""}`,
    tokenConfig()
  );

export const DELETE_SURVEY_RESPONSE = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/survey-response/${id}/`,
    tokenConfig()
  );
// survey token END
// GET_EMAIL_DELIVERY_STATUS START
export const GET_EMAIL_DELIVERY_STATUS = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/email-delivery-status/${
      payload ? payload : ""
    }`,
    tokenConfig()
  );
//  GET_EMAIL_DELIVERY_STATUS END

export const GET_PAYMENTS = () => {
  return;
};

export const FETCH_PAYMENT = () => {
  return;
};

export const ADD_PAYMENT = () => {
  return;
};
export const EDIT_PAYMENT = () => {
  return;
};

export const DELETE_PAYMENT = () => {
  return;
};

export const GET_PDF_TEMPLATES = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/pdf-template/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const FETCH_PDF_TEMPLATE = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/pdf-template/${payload.id}/`,
    tokenConfig()
  );

export const CREATE_PDF_TEMPLATE = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/pdf-template/`,
    payload,
    tokenConfig()
  );

// Action Plan API Calls
export const GET_ALL_ACTION_PLANS = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/action-plan/?${payload}`,
    tokenConfig()
  );

export const FETCH_ACTION_PLAN = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/action-plan/${payload.id}/`,
    tokenConfig()
  );

export const CREATE_ACTION_PLAN = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/action-plan/`,
    payload,
    tokenConfig()
  );

export const UPDATE_ACTION_PLAN = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/action-plan/${payload.id}/`,
    payload.data,
    tokenConfig()
  );

export const DELETE_ACTION_PLAN = (payload) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/action-plan/${payload}/`,
    tokenConfig()
  );

export const GET_ACTION_PLAN_ACTIVITY = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/action-plan-activity/?${payload}`,
    tokenConfig()
  );

export const GET_ACTION_PLAN_ACTIVITIES = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/action-plan-activity/?${payload}`,
    tokenConfig()
  );

export const ADD_ACTIVITY_ITEM = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/action-plan-activity/`,
    payload,
    tokenConfig()
  );

export const UPDATE_ACTIVITY_ITEM = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/action-plan-activity/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const DELETE_ACTIVITY_ITEM = (payload) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/action-plan-activity/${payload}/`,
    tokenConfig()
  );

// Employee Record
export const GET_EMPLOYEE_RECORD = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/employee-record/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const FETCH_EMPLOYEE_RECORD = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/employee-record/${payload.id}/`,
    tokenConfig()
  );

export const ADD_EMPLOYEE_RECORD = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/employee-record/`,
    payload,
    tokenConfig()
  );

export const UPDATE_EMPLOYEE_RECORD = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/employee-record/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const DELETE_EMPLOYEE_RECORD = (payload) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/employee-record/${payload}/`,
    tokenConfig()
  );

// Employee Record

// Employee Log
export const GET_EMPLOYEE_LOG = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/employee-log/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const FETCH_EMPLOYEE_LOG = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/employee-log/${payload.id}/`,
    tokenConfig()
  );

export const ADD_EMPLOYEE_LOG = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/employee-log/`,
    payload,
    tokenConfig()
  );

export const UPDATE_EMPLOYEE_LOG = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/employee-log/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const DELETE_EMPLOYEE_LOG = (payload) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/employee-log/${payload}/`,
    tokenConfig()
  );

// Employee Log

// Employee employee-record-list
export const GET_EMPLOYEE_RECORD_LIST = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/employee-record-list/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const FETCH_EMPLOYEE_RECORD_LIST = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/employee-record-list/${payload.id}/`,
    tokenConfig()
  );

export const ADD_EMPLOYEE_RECORD_LIST = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/employee-record-list/`,
    payload,
    tokenConfig()
  );

export const UPDATE_EMPLOYEE_RECORD_LIST = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/employee-record-list/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const DELETE_EMPLOYEE_RECORD_LIST = (payload) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/employee-record-list/${payload}/`,
    tokenConfig()
  );

// Employee employee-record-list

// Employee employee-record-value
export const GET_EMPLOYEE_RECORD_VALUE = (payload) => {
  return axios.get(
    `${urlconfig.appapi}/${VERSION}/employee-record-value/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );
};

export const FETCH_EMPLOYEE_RECORD_VALUE = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/employee-record-value/${payload.id}/`,
    tokenConfig()
  );

export const ADD_EMPLOYEE_RECORD_VALUE = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/employee-record-value/`,
    payload,
    tokenConfig()
  );

export const UPDATE_EMPLOYEE_RECORD_VALUE = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/employee-record-value/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const DELETE_EMPLOYEE_RECORD_VALUE = (payload) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/employee-record-value/${payload}/`,
    tokenConfig()
  );

// Employee employee-record-value
// survey standard START
export const GET_SURVEY_STANDARD = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/survey-standard/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const FETCH_SURVEY_STANDARD = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/survey-standard/${payload.id}/`,
    tokenConfig()
  );

export const ADD_SURVEY_STANDARD = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/survey-standard/`,
    payload,
    tokenConfig()
  );

export const EDIT_SURVEY_STANDARD = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/survey-standard/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const DELETE_SURVEY_STANDARD = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/survey-standard/${id}/`,
    tokenConfig()
  );
// survey standard END

//CHAT_RECOMMENDATIONSTART
export const GET_CHAT_RECOMMENDATION = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/chatgpt-recommendation/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const GET_DATA_STORIES = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/chatgpt-recommendation/datastories/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const GET_DATA_JOURNEYS = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/chatgpt-recommendation/recommendations/`,
    tokenConfig()
  );
// U6OCNANPTYRIHRE2KSMKJYU6ABLJJH5W3ABDIFKW

export const FETCH_CHAT_RECOMMENDATION = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/chatgpt-recommendation/${payload.id}/`,
    tokenConfig()
  );

export const ADD_CHAT_RECOMMENDATION = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/chatgpt-recommendation/`,
    payload,
    tokenConfig()
  );

export const EDIT_CHAT_RECOMMENDATION = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/chatgpt-recommendation/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const DELETE_CHAT_RECOMMENDATION = (payload) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/chatgpt-recommendation/${payload}/`,
    tokenConfig()
  );
// CHAT_RECOMMENDATION END

// RECOMMENDATION_TEMPLATES START
export const GET_REPORT_TEMPLATE = () =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/recommendation-template`,
    tokenConfig()
  );

export const GET_STORY_TEMPLATES = () =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/schedule-template/recommendation-model-list/`,
    tokenConfig()
  );

export const GET_PENDING_SHEDULE_REPORTS = () =>
  axios.get(`${urlconfig.appapi}/${VERSION}/schedule-template/`, tokenConfig());

//
export const GET_ENGAGEMENT_DATA = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/engagement-data/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

//

// export const GET_CHAT_GPT = (payload) =>
//   axios.get(
//     `${urlconfig.appapi}/${VERSION}/chat-gpt-response/${payload ? `?${payload}` : ""
//   }`,
//     tokenConfig()
//   );

export const POST_CHAT_GPT = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/chat-gpt-query/`,
    payload,
    tokenConfig()
  );
//

// PDF Saved Comments
export const GET_PDF_SAVED_COMMENTS = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/pdf-comment/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

// PDF Create Comments
export const CREATE_PDF_SAVED_COMMENTS = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/pdf-comment/`,
    payload,
    tokenConfig()
  );

// UPDATE PDF Comments
export const UPDATE_PDF_SAVED_COMMENTS = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/pdf-comment/${payload.id}/`,
    payload,
    tokenConfig()
  );

// DELETE PDF Comments
export const DELETE_PDF_SAVED_COMMENTS = (payload) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/pdf-comment/${payload}/`,
    tokenConfig()
  );

// GET REACT PDF REPORTS TEMPLATES
export const GET_DEBRIEF_PDF_REPORT_TEMPLATES = () =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/debrief-pdf-report/templatelist`,
    tokenConfig()
  );

// GET REACT PDF REPORTS
export const GET_DEBRIEF_PDF_REPORT = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/debrief-pdf-report/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

// // CREATE REACT PDF REPORTS
export const CREATE_DEBRIEF_PDF_REPORT = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/debrief-pdf-report/`,
    payload,
    tokenConfig()
  );

// FETCH REACT PDF REPORTS
export const FETCH_DEBRIEF_PDF_REPORT = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/debrief-pdf-report/${payload.id}/`,
    tokenConfig()
  );

// // UPDATE REACT PDF REPORTS
// export const UPDATE_DEBRIEF_PDF_REPORT = (payload) =>
//   axios.patch(
//     `${urlconfig.appapi}/${VERSION}/debrief-pdf-report/${payload.id}/`,
//     payload,
//     tokenConfig()
//   );

// DELETE REACT PDF REPORTS
export const DELETE_DEBRIEF_PDF_REPORT = (payload) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/debrief-pdf-report/${payload}/`,
    tokenConfig()
  );

// DELETE SPECIFIC REPORT
export const DELETE_DEBRIEF_PDF_REPORT_ITEM = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/debrief-pdf-report-item/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const GET_DEBRIEF_PDF_REPORT_URL = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/debrief-pdf-report/secure_aws_download_url/`,
    payload,
    tokenConfig()
  );

// GET LOGIN TOKEN to ML server
export const AUTO_LOGIN_TO_ML = () =>
  axios.post(`${urlconfig.appapi}/${VERSION}/login-to-ml/`, {}, tokenConfig());

// // this is not fully intergrated yet
// Event Notifications
export const EVENT_NOTIFICATION = (payload) => {
  return axios.get(
    `${urlconfig.appapi}/${VERSION}/organization-events/`,
    {},
    tokenConfig()
  );
};

// ML SERVER
export const POST_ML_SERVER_V1 = (payload) => {
  const chatToken = localStorage.getItem("chat_token");
  return axios.post(
    `${urlconfig.mlapi}/generate-chat-gpt/v1.0/`,
    payload.request,
    tokenMl(chatToken)
  );
};

// ML LONG QUERY SERVER
export const POST_ML_SERVER_V1_XL = (payload) => {
  const chatToken = localStorage.getItem("chat_token");
  return axios.post(
    `${urlconfig.mlapi}/generate-chat-gpt/v1.2/`,
    {},
    tokenMl(chatToken)
  );
};
//
// ML CALL FOR PDF + SIMPLE
export const POST_ML_SERVER_V1_4 = (payload) => {
  const chatToken = localStorage.getItem("chat_token");
  return axios.post(
    `${urlconfig.mlapi}/generate-chat-gpt/v1.4/`,
    payload.request,
    tokenMl(chatToken)
  );
};

// websocket servers

export function fetchChatRooms({ id }) {
  // Make the API call using Axios
  return axios.get(
    `${urlconfig.chatApi}/chat-topic/${id}`,
    // payload,
    chatTokenConfig()
  );
}

export const getChatRooms = (payload) => {
  // const chatToken = "osowcnormp";
  return axios.get(
    payload
      ? `${urlconfig.chatApi}/chat-topics?${payload}`
      : `${urlconfig.chatApi}/chat-topics`,
    // payload,
    chatTokenConfig()
  );
};

export const createChatRoom = (payload) => {
  // const chatToken = "osowcnormp";
  // console.log(payload)
  return axios.post(
    `${urlconfig.chatApi}/chat-topic`,
    payload,
    chatTokenConfig()
  );
};
// // live with data
export const WEBSOCKET_URL = ({ token, name, web_chat_id }) => {
  // const chatToken = "osowcnormp";
  return `${urlconfig.wsApi}/ws-1/${token}/${name}?web_chat_id=${web_chat_id}`;
};
// // dummy non data
// export const WEBSOCKET_URL = ({ token, name, web_chat_id }) => {
//   // const chatToken = "osowcnormp";
//   return `${urlconfig.wsApi}/ws/${token}/${name}?web_chat_id=${web_chat_id}`;
// };

export const DELETE_CHAT_ROOM = (payload) => {
  const chatToken = localStorage.getItem("chat_token");
  // const chatToken = "osowcnormp";

  return axios.delete(
    `${urlconfig.chatApi}/chat-topic/${payload.id}`,
    tokenMl(chatToken)
    // {
    //   headers: {
    //     Authorization: `Bearer osowcnormp`,
    //   },
    // }
  );
};

// DATA STORIES CRUD
export const ADD_SCHEDULE_TEMPLATE = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/schedule-template/`,
    payload,
    tokenConfig()
  );

export const UPDATE_SCHEDULE_TEMPLATE = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/schedule-template/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const DELETE_SCHEDULE_TEMPLATE = (payload) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/schedule-template/${payload}/`,
    tokenConfig()
  );

export const GET_MANAGER_USERS = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/employee/manager-list/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const ADD_MANAGER_USER = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/employee/create-update-managers/`,
    payload,
    tokenConfig()
  );

export const ADD_EMAIL_TEMPLATE = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/task-template-email/`,
    payload,
    tokenConfig()
  );

export const UPDATE_EMAIL_TEMPLATE = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/task-template-email/${payload.id}/`,
    payload,
    tokenConfig()
  );

// Task Templates
export const GET_TASK_TEMPLATES = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/task-template-user-tasks/${
      payload ? `${payload}` : ""
    }`,
    tokenConfig()
  );

// Data stories logging
export const ADD_DATA_STORY_LOG = (payload) => {
  return axios.post(
    `${urlconfig.appapi}/${VERSION}/datastory-log/update/`,
    payload,
    tokenConfig()
  );
};

// Get users logs for data stories
export const GET_DATA_STORY_LOGS = (payload) => {
  return axios.get(
    `${urlconfig.appapi}/${VERSION}/datastory-log/list${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );
};

export const ADD_TASK_TEMPLATE = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/task-template-user-tasks/`,
    payload,
    tokenConfig()
  );

export const UPDATE_TASK_TEMPLATE = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/task-template-user-tasks/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const DELETE_TASK_TEMPLATE = (payload) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/task-template/${payload}/`,
    tokenConfig()
  );

export const GET_TASK_TEMPLATE_SELECT = (payload) =>
  axios.get(
    `${
      urlconfig.appapi
    }/${VERSION}/task-template-user-tasks/user-tasks-select/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const CREATE_TASK_SCHEDULE = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/task-template/`,
    payload,
    tokenConfig()
  );

export const GET_TASK_SCHEDULES = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/task-template/${
      payload ? `${payload}` : ""
    }`,
    tokenConfig()
  );

// Assigning task templates.
export const ADD_USER_TASK = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/task-template/add-employee/`,
    payload,
    tokenConfig()
  );

export const DELETE_USER_TASK = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/task-template/remove-employee/`,
    payload,
    tokenConfig()
  );

export const GENERATE_TASK_REPORT = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/task-template/generate_report/`,
    payload,
    tokenConfig()
  );

export const GET_TASK_REPORTS = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/task-template/generate_report/${
      payload ? `${payload}` : ""
    }`,
    tokenConfig()
  );

export const ADD_EMPLOYEE_REPORT_ACCESS = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/task-template/add-employee-access/`,
    payload,
    tokenConfig()
  );

export const REMOVE_EMPLOYEE_REPORT_ACCESS = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/task-template/remove-employee-access/`,
    payload,
    tokenConfig()
  );

export const ADD_USER_TASK_RESPONSE = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/task-template-user-response/`,
    payload,
    tokenConfig()
  );

export const GET_USER_TASK_RESPONSE = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/task-template-user-response/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const UPDATE_USER_TASK_RESPONSE = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/task-template-user-response/${payload.task_template}/`,
    payload,
    tokenConfig()
  );

export const FETCH_TASK_TEMPLATE = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/task-template-user-tasks/${payload}`,
    tokenConfig()
  );

export const GENERATE_AWS_DOWNLOAD_URL = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/generate-aws-download-link/`,
    payload,
    tokenConfig()
  );