import styled, { keyframes } from "styled-components";
import React, { useEffect, useCallback, useState, useRef } from "react";
import {Table,Label} from "semantic-ui-react"

const ROISummary = ({data}) =>{

    const [factor_data,setFactorData] = useState([])
    const [roi,setROI] = useState([])

    const process_data = () => {
        let factor_data = data[0].effect_data.map((item)=>{
            return {
                name:item.factor,
                roi:[]
            }
        })
        data.map((item)=>{  
            item.effect_data.map((effect)=>{
                let coefficient = item.score.model_without_outliers[effect.factor].coefficient
                let SE = item.score.model_without_outliers[effect.factor].SE
                let impact = coefficient * 0.1 + SE*1.96 * 0.1 * coefficient
                factor_data.find((f)=>f.name == effect.factor).roi.push(impact)
            })
        })

        factor_data.sort((a,b)=>{
            let a_sum = a.roi.reduce((a,b)=>a+b,0)
            let b_sum = b.roi.reduce((a,b)=>a+b,0)
            return b_sum-a_sum
        })
       return factor_data
                
    }


    useEffect(()=>{
        if(data.length>0){
            setFactorData(process_data())
        }
    },[data])

    useEffect(()=>{
        if(factor_data.length>0){
           setROI(classifyROI(factor_data))
        }
    },[factor_data])


    // Function to calculate the total ROI
function calculateTotalROI(roiArray) {
    return roiArray.reduce((acc, value) => acc + value, 0);
  }
  
  // Function to classify ROI impact based on comparison
  function classifyROI(roiData) {
    // Calculate total ROI for each outcome
    const totals = roiData.map(item => ({
      name: item.name,
      totalROI: calculateTotalROI(item.roi)
    }));
  
    // Sort to identify the highest, high, moderate, and low ranges
    const sortedTotals = [...totals].sort((a, b) => b.totalROI - a.totalROI);
  
    // The absolute highest value
    const highestROI = sortedTotals[0].totalROI;
  
    // Set thresholds for classification
    const highThreshold = highestROI * 0.75;
    const moderateThreshold = highestROI * 0.5;
  
    // Classify each item
    return sortedTotals.map((item, index) => {
      let classification = "low"; // Default classification
  
      if (index === 0) {
        // The single highest ROI is classified as "highest"
        classification = "highest";
      } else if (item.totalROI >= highThreshold) {
        classification = "high";
      } else if (item.totalROI >= moderateThreshold) {
        classification = "moderate";
      }
  
      return {
        name: item.name,
        totalROI: item.totalROI,
        classification: classification
      };
    });
  }
  

const colorMap = {
    'highest':'green',
    'moderate':'olive',
    'low':'grey'
}

    return (

        <Container>
            <Title>
                ROI Summary
            </Title>

            <Description>
                The table below shows the potential ROI impact of each culture factor. The factors are ranked from highest to lowest based on the total ROI potential.
                Factors that have the highest ROI potential will yield the largest increase in outcome measures for a given effort.
            </Description>
        <Table>
            <Table.Header>
                <Table.Row>
                <Table.HeaderCell>Culture Factor Rank</Table.HeaderCell>
                    {   
                        data?.map((item)=>{
                            return <Table.HeaderCell>{item.outcome.name}</Table.HeaderCell>
                        })
                    }

                    <Table.HeaderCell>Total ROI Potential</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {
                    factor_data.map((item,index)=>{
                        let classification = roi?.find(f=>f.name==item.name)?.classification
                        return <Table.Row>
                            <Table.Cell>{index+1}. {item.name}</Table.Cell>
                            {
                                item.roi.map((roi)=>{
                                    return <Table.Cell>{(roi*100).toFixed(0)}%</Table.Cell>
                                })
                            }
                            <Table.Cell>
                                <Label color={colorMap[classification]}>
                                {classification}
                                </Label>
                            </Table.Cell>
                        </Table.Row>
                    })
                }
            </Table.Body>


        </Table>
           
        </Container>
    )
}

export default ROISummary;


const Container = styled.div`
    font-family:'Raleway';
    margin-top:50px;
`

const Tag = styled.div`
    background-color:#f6f6f6;
    border-radius:5px;
    padding:5px;
    text-align:center;
    font-size:12px;
    color:#333;
    font-weight:bold;
    display:inline-block;
`

const Title = styled.div`
    font-size:24px;
    font-weight:bold;
    margin-bottom:10px;
`

const Description = styled.div`
    margin-bottom:20px;
`