import React, { useState, useEffect, useRef } from "react";
import styled, { keyframes } from "styled-components";
import { SG_UPDATE_EMAIL_TEMPLATE } from "constants/actions";
import { useToasts } from "react-toast-notifications";
import TextEditor from "components/TextEditor";
import { useDispatch } from "react-redux";

const EmailTemplate = ({ task, id }) => {
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const [emailTemplate, setEmailTemplate] = useState(task?.task_template_email);
  const [emailSubject, setEmailSubject] = useState();
  const [emailBody, setEmailBody] = useState();
  const [updatedBody, setUpdatedBody] = useState();

  useEffect(() => {
    if (task?.task_template_email) {
      setEmailTemplate(task?.task_template_email);
      setEmailBody(task?.task_template_email?.email_content);
      setEmailSubject(task?.task_template_email?.email_subject);
    }
  }, [task]);

  const handleSave = () => {
    const payloadObj = {
      email_subject: emailSubject,
      email_content: updatedBody,
      task_template: id,
      id: emailTemplate?.id,
    };

    dispatch({
      type: SG_UPDATE_EMAIL_TEMPLATE,
      payload: {
        ...payloadObj,
      },
    });

    // Add error handling
    addToast("Email template updated successfully", {
      appearance: "success",
      autoDismiss: true,
    });
  };

  const handleEditorChange = (content) => {
    console.log(content);
    setUpdatedBody(content);
  };

  return (
    <EmailTemplateContainer>
      <WarningMessage>
        Please note: Changes to this email template will only apply to newly
        invited users. Users who have already been invited to the survey will
        not receive the updated email format.
      </WarningMessage>
      <EmailTemplateHeader>
        <EmailTemplateTitle>Email Template</EmailTemplateTitle>
        <SaveButton onClick={handleSave}>Save</SaveButton>
      </EmailTemplateHeader>
      <EmailTemplateSubject
        value={emailSubject}
        onChange={(e) => setEmailSubject(e.target.value)}
        placeholder="Subject"
      />
      <TextEditor value={emailBody} onChange={handleEditorChange} />
    </EmailTemplateContainer>
  );
};

export default EmailTemplate;

const EmailTemplateContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

const EmailTemplateHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

const EmailTemplateTitle = styled.div`
  font-size: 14px;
  font-weight: bold;
`;

const SaveButton = styled.button`
  background: #2d70e2;
  border-radius: 8px;
  font-family: "Raleway";
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  border: none;
  padding: 12px 24px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;

const EmailTemplateSubject = styled.input`
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 15px;
`;

const fadeIn = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;
const WarningMessage = styled.div`
  background-color: #e7f3fe;
  color: #0c5460;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 15px;
  font-size: 12px;
  border: 1px solid #b8daff;
`;
