import { call, put, all, takeEvery, takeLatest } from "redux-saga/effects";
import * as api from "constants/api";

import {
  GENERATE_AWS_DOWNLOAD_URL,
  SG_GENERATE_AWS_DOWNLOAD_URL,
  CLEAR_AWS_DOWNLOAD_URL,
  ALL_ERRORS,
} from "constants/actions";

function* generateDownloadUrl(action) {
  try {
    const json = yield call(api.GENERATE_AWS_DOWNLOAD_URL, action.payload);
    yield put({ type: GENERATE_AWS_DOWNLOAD_URL, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* downloadUrl() {
  yield takeLatest(SG_GENERATE_AWS_DOWNLOAD_URL, generateDownloadUrl);
}

function* clearDownloadUrl(action) {
  try {
    yield put({ type: CLEAR_AWS_DOWNLOAD_URL });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* clearDownloadUrlSaga() {
  yield takeLatest(CLEAR_AWS_DOWNLOAD_URL, clearDownloadUrl);
}

export default function* rootSaga() {
  yield all([downloadUrl(), clearDownloadUrlSaga()]);
}
