import {
  GENERATE_AWS_DOWNLOAD_URL,
  SG_GENERATE_AWS_DOWNLOAD_URL,
  CLEAR_AWS_DOWNLOAD_URL,
} from "constants/actions";

const initialState = {
  download_url: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GENERATE_AWS_DOWNLOAD_URL:
      return {
        ...state,
        download_url: action.payload,
      };
    case CLEAR_AWS_DOWNLOAD_URL:
      return {
        ...state,
        download_url: null,
      };
    default:
      return state;
  }
}
