import React, { useState, useEffect,useRef  } from "react";
import styled, { keyframes, css } from "styled-components";




const Intro = () =>{

    return (
        <Container>
            <Title>
                Let AI help you get deeper insights.
            </Title>

            <Description>
                Our AI will help design a custom survey for you based on your culture data, and your specific needs. 
                The survey will follow best practices in survey design, and will ensure that you are asking the right questions to get the most accurate and actionable insights.
            </Description>

          
        </Container>
    )
}

export default Intro;


const Container = styled.div`
    mwidth:100%;
`

const Title = styled.h1`

`

const Description = styled.p`

`

const Button = styled.button`
    
    `

const ButtonArea = styled.div`
    display:flex;
    width:100%;
    justify-content:flex-end;
    margin-top:40px;
`