import { useEffect, useState } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import {
  SG_GET_SURVEY_STRUCTURES,
  SG_GET_EMPLOYEE_CATEGORIES,
  SG_GET_SURVEY_QUESTIONS,
  SG_GET_CULTURE_AUDIT_REPORTS,
  SG_GET_SURVEY_STANDARD,
} from "constants/actions";

const personality_names = [
  ["Extraversion", "Extraverted"],
  ["Conscientiousness", "Conscientiousness"],
  ["Agreeableness", "Agreeable"],
  ["Neuroticism", "Neurotic"],
  ["Openess", "Open"],
];

const personality_categories = personality_names.map((item) => {
  return {
    id: item[0],
    name: item[0],
    options: [
      { id: "0", name: "Least " + item[1], color: "blue" },
      { id: "1", name: "Moderately " + item[1], color: "red" },
      { id: "2", name: "Most " + item[1], color: "green" },
    ],
    priority: "personality",
    subtitle: item,
    demographic: true,
  };
});

// Using prioritiesToFilterStructure and surveyStructureFiltered
// find the matching priorities and from the surveyStructureFiltered?.categories?.id
// and filter out any of the options that are not in the prioritiesToFilterStructure?.values array.
// If the prioritiesToFilterStructure?.values array is empty, then return the surveyStructureFiltered
// as is.
const filterSurveyStructure = (
  surveyStructureFiltered,
  prioritiesToFilterStructure
) => {
  if (prioritiesToFilterStructure?.length === 0) {
    return surveyStructureFiltered;
  }

  // Create a deep copy of surveyStructureFiltered
  const surveyStructureCopy = JSON.parse(
    JSON.stringify(surveyStructureFiltered)
  );

  const filteredCategories = surveyStructureCopy?.[0]?.categories?.map(
    (category) => {
      const permissionsToFilter = prioritiesToFilterStructure?.find(
        (priority) => priority?.priority === category?.id
      );

      if (!permissionsToFilter || category.priority === "primary") {
        return category;
      }

      const filteredOptions = category?.options?.filter((option) =>
        permissionsToFilter?.values?.includes(option?.id)
      );

      const updatedCategory = { ...category, options: filteredOptions };

      return updatedCategory;
      //
    }
  );

  const newSurveyStructureFiltered = {
    ...surveyStructureCopy[0],
    categories: filteredCategories,
  };

  return [newSurveyStructureFiltered];
};


// Uses get_culture_audit_reports,  go through reportData?.response and filter out the responses that do not meet the prioritiesToFilterStructure
// the responses represent a survey respondant and the goal is to filter out any respondants that do not meet the prioritiesToFilterStructure
export const filterReportResponses = (reportData, prioritiesToFilterStructure) => {
  if (prioritiesToFilterStructure?.length === 0) {
      return reportData
  }

  const filteredSurveys = reportData?.response?.map(survey => {
      const filteredResponses = survey?.responses?.filter(response => {
          return prioritiesToFilterStructure?.every(priority => {
              const priorityId = priority?.priority;
              const priorityValues = priority?.values;
              const matchingResponse = response?.response?.categories?.find(category => {

                  return category?.id === priorityId && priorityValues?.includes(category?.response)
              })
              return matchingResponse
          })
      })
      const updatedSurvey = { ...survey }
      updatedSurvey.responses = filteredResponses
      return updatedSurvey
  })
  reportData.response = filteredSurveys
  return reportData
}

/**
 *
 * @param {auditTypeId: int, surveyType: int } params
 * @returns
 */
function useGetSurveyData(params) {
  const dispatch = useDispatch();
  const [organizationId, setOrganizationId] = useState(null);
  const [organizationData, setOrganizationData] = useState(null);
  const [selectedReport, setSelectedReport] = useState();
  const [standards, setStandards] = useState(null);
  const [surveyStruct, setSurveyStruct] = useState();
  const [isOtp, setIsOtp] = useState();
  const [orgSurveyQuestions, setSurveyQuestions] = useState([]);
  const [filteredReport, setFilteredReport] = useState();

  const {
    employeeId,
    get_auth,
    get_selectedOrg,
    get_survey_structure,
    get_employee,
    get_organizations,
    get_employee_categories,
    get_survey_questions,
    get_errors,
    WhiteLabel,
    get_culture_audit_reports,
    sub_state,
    nav_state,
    get_employee_permission,
  } = useSelector(
    (state) => ({
      employeeId: Number(state.auth?.employee_id),
      get_errors: state.errors,
      get_auth: state.auth,
      get_organizations: state.organizations,
      get_employee: state.employees,
      get_selectedOrg: state.selectedOrg,
      get_survey_structure: state.surveystructure.survey_structure,
      get_employee_categories:
        state.employees.userEmp.employee_employee_category,
      get_survey_questions: state.surveyquestions.survey_questions,
      get_culture_audit_reports: state.debrief_schedule.culture_audit_reports,
      sub_state: state.audit.sub,
      nav_state: state.audit.nav,
      WhiteLabel: state.white_label?.white_label,
      get_employee_permission: state.employee_permission,
    }),
    shallowEqual
  );

  const ambassadorId = useSelector(
    (state) => Number(state.auth?.ambassador_id),
    shallowEqual
  );

  const org = useSelector((state) => {
    return state.organizations;
  });

  const get_standards = useSelector((state) => {
    return state.survey_standard.survey_standards;
  });

  let surveyType;
  let auditTypeId;
  if (org) {
    surveyType = org.organization.services_enabled?.find(
      (f) => f.name === "OTP"
    )?.enabled
      ? 8
      : params.surveyType;
    auditTypeId = org.organization.services_enabled?.find(
      (f) => f.name === "OTP"
    )?.enabled
      ? 5
      : params.auditTypeId;
  }

  useEffect(() => {
    setSurveyQuestions(get_survey_questions);
  }, [get_survey_questions]);

  useEffect(() => {
    if (org) {
      const isOTP = org.organization.services_enabled?.find(
        (f) => f.name === "OTP"
      )?.enabled;
      setIsOtp(isOTP);
    }
  }, [org]);

  useEffect(() => {
    if (organizationId) {
      if (!get_survey_structure[0]) {
        dispatch({
          type: SG_GET_SURVEY_STRUCTURES,
          payload: `organization=${organizationId}`,
        });
      }
    }

    if (get_standards.length == 0) {
      dispatch({
        type: SG_GET_SURVEY_STANDARD,
      });
    }

    if (surveyType) {
      dispatch({
        type: SG_GET_SURVEY_QUESTIONS,
        payload: `survey_type=${surveyType}&status=2`,
      });
    }

    if (
      auditTypeId &&
      !params.dontRefetch &&
      (!get_culture_audit_reports?.response || !params.auditReports?.response)
    ) {
      if (ambassadorId) {
        dispatch({
          type: SG_GET_CULTURE_AUDIT_REPORTS,
          payload: `audit-type-id=${auditTypeId}&organization=${organizationId}`,
        });
      } else {
        dispatch({
          type: SG_GET_CULTURE_AUDIT_REPORTS,
          payload: `audit-type-id=${auditTypeId}`,
        });
      }
    }
    // console.log(get_employee_categories)
    if (employeeId && get_employee_categories?.categories?.length === 0) {
      dispatch({
        type: SG_GET_EMPLOYEE_CATEGORIES,
        payload: `employee=${employeeId}`,
      });
    }

    if (org && !organizationData) {
      setOrganizationData(org[organizationId]);
    }

    if (
      params.reportId &&
      (get_culture_audit_reports.response || params.auditReports?.response)
    ) {
      const auditReports =
        params.auditReports?.response || get_culture_audit_reports.response;
      const matchedReport = auditReports.find(
        (responseGroup) => responseGroup.id === params.reportId
      );

      setSelectedReport(matchedReport);
    }

    //check if personality is present?
    if (
      org?.organization?.services_enabled?.find((f) => f.id == 21)?.enabled &&
      get_survey_structure[0]
    ) {
      if (
        !get_survey_structure[0].categories.find((f) => f.id == "Extraversion")
      ) {
        get_survey_structure[0].categories = [
          ...get_survey_structure[0].categories,
          ...personality_categories,
        ];
      }
    }

    if (params.dateStart && params.dateEnd) {
      const auditReportsResponses =
        params.auditReports?.response || get_culture_audit_reports.response;
      let reportsRange = auditReportsResponses?.filter(
        (responseGroup) =>
          new Date(responseGroup.date) <= params.dateEnd &&
          new Date(responseGroup.date) >= params.dateStart
      );

      if (reportsRange?.length > 0) {
        if (params.sports?.length > 0) {
          reportsRange = reportsRange.filter((group) => {
            return params.sports.includes(group.name);
          });
        }

        const reportsOrdered = reportsRange?.sort(function (a, b) {
          return b.id - a.id;
        });

        if (params.edi && !params.reportId) {
          setSelectedReport(reportsOrdered);
        } else {
          setSelectedReport(reportsOrdered?.[0]);
        }
      }
    }
    
   
  }, [
    organizationId,
    dispatch,
    employeeId,
    ambassadorId,
    params.auditTypeId,
    params.surveyType,
    org,
    params.reportId,
    params.dateStart,
    params.dateEnd,
    params.sports
  ]);


  useEffect(()=>{
    
    if (params.survey_version) {
      setStandards(
        get_standards.find((f) =>
          f.question_sort_order.includes(params.survey_version.sort_order)
        )
      );
    }
  
  },[params.survey_version,get_standards])

  useEffect(() => {
    if (Number(ambassadorId) > 0) {
      setOrganizationId(get_selectedOrg?.organization?.id);
    } else {
      setOrganizationId(Number(get_auth.organization_id));
    }
  }, [get_selectedOrg, ambassadorId, get_auth]);

  useEffect(() => {
    if (
      params.reportId &&
      (get_culture_audit_reports.response || params.auditReports?.response) &&
      params.edi
    ) {
      const auditReports =
        params.auditReports?.response || get_culture_audit_reports.response;
      const matchedReport = auditReports.find(
        (responseGroup) => responseGroup.id === params.reportId
      );

      setSelectedReport(matchedReport);
    }
  }, [
    params.reportId,
    get_culture_audit_reports,
    params.edi,
    params.auditReports,
  ]);

  useEffect(() => {
    if (
      get_survey_structure &&
      get_survey_structure.length > 0 &&
      params.filteredCategories
    ) {
      let surveyStructureFiltered = get_survey_structure?.map((s) => {
        if (params?.filteredCategories?.length > 0 && !params?.isAdmin) {
          const tempCategories = [...s?.categories];
          const primaryCategories = s?.categories?.find(
            (c) => c.priority === "primary"
          );
          const replaceIndex = s?.categories?.findIndex(
            (c) => c.priority === "primary"
          );
          if (surveyType === 8) {
            const filteredOptions = primaryCategories?.options.filter((so) => {
              return so.subfactors.filter((sub) =>
                params.filteredCategories?.includes(sub.name)
              );
            });

            tempCategories[replaceIndex].options = filteredOptions;
            return { ...s, categories: tempCategories };
          } else {
            const filteredOptions = primaryCategories?.options.filter((so) => {
              return params.filteredCategories?.includes(so.name);
            });
            tempCategories[replaceIndex].options = filteredOptions;
            return { ...s, categories: tempCategories };
          }
        } else {
          return { ...s };
        }
      });

      if (
        org.organization.services_enabled.find((f) => f.id == 21)?.enabled &&
        surveyStructureFiltered[0]
      ) {
        if (
          !surveyStructureFiltered[0].categories.find(
            (f) => f.id == "Extraversion"
          )
        ) {
          surveyStructureFiltered[0].categories = [
            ...surveyStructureFiltered[0].categories,
            ...personality_categories,
          ];
        }
      }

      if (get_employee_permission?.employee_permission?.length > 0) {
        const currentEmployeePerms =
          get_employee_permission?.employee_permission?.filter(
            (f) => f.employee_id === employeeId
          );

        if (currentEmployeePerms?.length > 0) {
          const prioritiesToFilter = currentEmployeePerms[0]?.details?.map(
            (m) => {
              return { priority: m.name_id, value: m.value_id };
            }
          );

          // Create a new array of objects with prioritiesToFilter
          // loop through the array and add all of the values to a new array
          // with the same key as the priority.
          //  the structure should look like this:
          // [{priority: "d113fcb2-513c-4743-8e06-02534529da63", values: ["6a3a3a96-702e-4a15-9c52-fa390b202cc5"]}]
          const prioritiesToFilterStructure = prioritiesToFilter.reduce(
            (acc, curr) => {
              const { priority, value } = curr;
              const existingPriority = acc.find(
                (item) => item.priority === priority
              );
              if (existingPriority) {
                existingPriority.values.push(value);
              } else {
                acc.push({ priority, values: [value] });
              }
              return acc;
            },
            []
          );

          surveyStructureFiltered = filterSurveyStructure(
            surveyStructureFiltered,
            prioritiesToFilterStructure
          );

          if (get_culture_audit_reports) {
            const filteredReports = filterReportResponses(
              get_culture_audit_reports,
              prioritiesToFilterStructure
            );

            setFilteredReport(filteredReports);
          }
        }
        setSurveyStruct(surveyStructureFiltered);
      }
    }
  }, [
    get_survey_structure,
    params.filteredCategories,
    params.isAdmin,
    get_culture_audit_reports,
  ]);

  const filter_questions_for_deletion = (data) =>{
    if(data?.response && data?.response[0]?.name == "Podium North"){
      data.response[0].responses = data.response[0].responses.map(item=>{
          item.response.questions = item.response.questions.filter(f=>!f.reverse)
           return item
        })

        return data
    }

    return data
  }

  


  return {
    get_survey_structure: surveyStruct || get_survey_structure,
    get_errors,
    get_auth,
    get_organizations,
    get_employee,
    get_selectedOrg,
    get_employee_categories,
    get_survey_questions,
    get_culture_audit_reports: filteredReport || filter_questions_for_deletion(get_culture_audit_reports),
    sub_state,
    nav_state,
    WhiteLabel,
    organizationId,
    organizationData,
    selectedReport,
    ambassadorId,
    standards,
    isOtp,
    orgSurveyQuestions,
    get_standards
  };
}

export default useGetSurveyData;
