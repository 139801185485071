import React, { useState, useEffect,useRef  } from "react";
import styled, { keyframes, css } from "styled-components";



const Questions = ({questions,objective}) =>{


const parseMarkdownToHTML = (markdown) => {
    let html = '';
  
    const lines = markdown.split('\n');
  
    lines.forEach((line) => {
      if (line.includes('**')) {
        line = line.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
      }
  
      if (/^\d+\./.test(line.trim())) {
        html += `<li>${line.trim().slice(3)}</li>`;
      } else {
        html += `<p>${line.trim()}</p>`;
      }
    });
  
    if (html.includes('<li>')) {
      html = html.replace(/(<li>[\s\S]*<\/li>)/g, '<ol>$1</ol>');
    }
  
    return { __html: html };
  };

    return (
        <Container>
            <Title>Survey Questions</Title>
            <Description>
                This survey was designed by AI to accomplish the following objective:
                <br/>
                <strong>{objective}</strong>
            </Description>
             <div dangerouslySetInnerHTML={parseMarkdownToHTML(questions)} />
        </Container>
    )
}

export default Questions;

const Container = styled.div`
    white-space: pre-wrap;
    font-family:'Raleway'
`

const Title = styled.div`
    font-size:24px;
    font-weight:bold;
    margin-bottom:20px;
`

const Description = styled.div`
    margin-bottom:20px;
`